import React, { useEffect, useState } from 'react';
import QRCodeStyling from 'qr-code-styling';

import { CopyButton, QRButton } from '..';
import { getQrCodeStyles } from '../../helpers';

const AddressPublicKey = ({ publicKey }) => {
  const [qrCode, setQrCode] = useState(null);

  useEffect(() => {
    setQrCode(new QRCodeStyling(getQrCodeStyles(publicKey)));
  }, [publicKey]);

  return (
    <div className='flex items-center gap-1'>
      <div className='truncate'>{publicKey}</div>
      <CopyButton value={publicKey} />
      <QRButton qrCode={qrCode} />
    </div>
  );
};

export { AddressPublicKey };
