const getTransactionInputsIds = (inputs) => {
  if (!inputs?.length) {
    return [];
  }

  return inputs
    .flatMap((input) => input.previous_outpoint_hash)
    .filter(Boolean);
};

export { getTransactionInputsIds };
