import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Transition, Portal } from '@headlessui/react';
import clsx from 'clsx';

import { useMount } from '../hooks';

const Tooltip = ({ children, content }) => {
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const targetRef = useRef(null);
  const tooltipRef = useRef(null);

  const mounted = useMount(visible, 150);

  useEffect(() => {
    if (mounted) {
      updatePosition();
      window.addEventListener('resize', updatePosition);
      window.addEventListener('scroll', updatePosition, true);

      return () => {
        window.removeEventListener('resize', updatePosition);
        window.removeEventListener('scroll', updatePosition, true);
      };
    }
  }, [mounted, content]);

  const updatePosition = () => {
    if (targetRef.current && tooltipRef.current) {
      const targetRect = targetRef.current.getBoundingClientRect();
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const scrollLeft = window.scrollX || document.documentElement.scrollLeft;
      const viewportWidth = document.documentElement.clientWidth;

      let top = targetRect.top + scrollTop - tooltipRect.height;
      let left =
        targetRect.left +
        scrollLeft +
        targetRect.width / 2 -
        tooltipRect.width / 2;

      if (left < scrollLeft) {
        left = scrollLeft;
      }

      if (left + tooltipRect.width > viewportWidth + scrollLeft) {
        left = viewportWidth + scrollLeft - tooltipRect.width;
      }

      if (top !== position.top || left !== position.left) {
        setPosition({ top, left });
      }
    }
  };

  const handleMouseEnter = () => setVisible(true);
  const handleMouseLeave = () => setVisible(false);

  return (
    <>
      <div
        ref={targetRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        {children}
      </div>
      {(visible || mounted) && (
        <Tooltip.Content
          visible={visible}
          position={position}
          ref={tooltipRef}
          content={content}
        />
      )}
    </>
  );
};

Tooltip.Content = forwardRef(({ visible, content, position }, ref) => {
  return (
    <Portal>
      <Transition show={visible} appear>
        <div
          ref={ref}
          style={position}
          className={clsx(
            'absolute w-max max-w-[min(100%,200px)] p-1.5',
            'transition-opacity data-[closed]:opacity-0',
          )}>
          <div className='rounded-md bg-neutral-7/70 px-2 py-1 text-xs text-white'>
            {content}
          </div>
        </div>
      </Transition>
    </Portal>
  );
});

export { Tooltip };
