import React from 'react';
import { useNavigate } from 'react-router';

import variables from '../../configs/variables';
import { formatCoinsAmount } from '../../helpers';
import { SectionIcon } from '..';
import { TransactionIcon, TransactionSimpleIcon } from '../../icons';
import { TableCell, TableRow } from '../table';

const LatestTransactionRow = ({ transaction, small }) => {
  const navigate = useNavigate();

  const goToDetails = () => {
    navigate(`/txs/${transaction.txId}`);
  };

  const props = {
    transactionId: transaction.txId,
    amount: transaction.amount,
    recipient: transaction.address,
    goToDetails: goToDetails,
  };

  if (small) {
    return <LatestTransactionRow.Small {...props} />;
  }

  return <LatestTransactionRow.Big {...props} />;
};

LatestTransactionRow.Small = ({
  transactionId,
  amount,
  recipient,
  goToDetails,
}) => {
  return (
    <TableRow onClick={goToDetails}>
      <TableCell
        title='ID'
        value={
          <div className='flex items-center gap-2'>
            <TransactionSimpleIcon color='text-link' className='w-[12px]' />
            {transactionId.slice(0, 10)}
          </div>
        }
        className='table-cell-interaction'
      />
      <TableCell
        title='Amount'
        value={`${formatCoinsAmount(amount / variables.coinParts)} ${variables.coinSymbol}`}
        className='table-cell-interaction text-neutral-7 md:text-right'
      />
      <TableCell
        title='Recipient'
        value={recipient}
        className='table-cell-interaction'
        truncate
        last
      />
    </TableRow>
  );
};

LatestTransactionRow.Big = ({
  transactionId,
  amount,
  recipient,
  goToDetails,
}) => {
  return (
    <TableRow onClick={goToDetails}>
      <TableCell
        title='ID'
        value={
          <div className='flex items-center gap-2'>
            <SectionIcon icon={TransactionIcon} />
            <div className='truncate text-primary group-hover/row:text-link group-active/row:text-link'>
              {transactionId}
            </div>
          </div>
        }
        className='table-cell-interaction'
        truncate
      />
      <TableCell
        title='Amount'
        value={`${formatCoinsAmount(amount / variables.coinParts)} ${variables.coinSymbol}`}
        className='table-cell-interaction text-neutral-7 md:text-right'
      />
      <TableCell
        title='Recipient'
        value={recipient}
        className='table-cell-interaction'
        truncate
        last
      />
    </TableRow>
  );
};

export { LatestTransactionRow };
