import React from 'react';
import clsx from 'clsx';

const QRIcon = ({ color }) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      className={clsx('fill-current', color)}>
      <path d='M12.75 10.5H10.75C10.6119 10.5 10.5 10.6119 10.5 10.75V12.75C10.5 12.8881 10.6119 13 10.75 13H12.75C12.8881 13 13 12.8881 13 12.75V10.75C13 10.6119 12.8881 10.5 12.75 10.5Z' />
      <path d='M10.25 8.5H8.75C8.61193 8.5 8.5 8.61193 8.5 8.75V10.25C8.5 10.3881 8.61193 10.5 8.75 10.5H10.25C10.3881 10.5 10.5 10.3881 10.5 10.25V8.75C10.5 8.61193 10.3881 8.5 10.25 8.5Z' />
      <path d='M14.75 13H13.25C13.1119 13 13 13.1119 13 13.25V14.75C13 14.8881 13.1119 15 13.25 15H14.75C14.8881 15 15 14.8881 15 14.75V13.25C15 13.1119 14.8881 13 14.75 13Z' />
      <path d='M14.75 8.5H13.75C13.6119 8.5 13.5 8.61193 13.5 8.75V9.75C13.5 9.88807 13.6119 10 13.75 10H14.75C14.8881 10 15 9.88807 15 9.75V8.75C15 8.61193 14.8881 8.5 14.75 8.5Z' />
      <path d='M9.75 13.5H8.75C8.61193 13.5 8.5 13.6119 8.5 13.75V14.75C8.5 14.8881 8.61193 15 8.75 15H9.75C9.88807 15 10 14.8881 10 14.75V13.75C10 13.6119 9.88807 13.5 9.75 13.5Z' />
      <path d='M12.75 3H10.75C10.6119 3 10.5 3.11193 10.5 3.25V5.25C10.5 5.38807 10.6119 5.5 10.75 5.5H12.75C12.8881 5.5 13 5.38807 13 5.25V3.25C13 3.11193 12.8881 3 12.75 3Z' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.5 2C8.5 1.44772 8.94772 1 9.5 1H14C14.5523 1 15 1.44772 15 2V6.5C15 7.05228 14.5523 7.5 14 7.5H9.5C8.94772 7.5 8.5 7.05228 8.5 6.5V2ZM14 2H9.5V6.5H14V2Z'
      />
      <path d='M5.25 3H3.25C3.11193 3 3 3.11193 3 3.25V5.25C3 5.38807 3.11193 5.5 3.25 5.5H5.25C5.38807 5.5 5.5 5.38807 5.5 5.25V3.25C5.5 3.11193 5.38807 3 5.25 3Z' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1 2C1 1.44772 1.44772 1 2 1H6.5C7.05228 1 7.5 1.44772 7.5 2V6.5C7.5 7.05228 7.05228 7.5 6.5 7.5H2C1.44772 7.5 1 7.05228 1 6.5V2ZM6.5 2H2V6.5H6.5V2Z'
      />
      <path d='M5.25 10.5H3.25C3.11193 10.5 3 10.6119 3 10.75V12.75C3 12.8881 3.11193 13 3.25 13H5.25C5.38807 13 5.5 12.8881 5.5 12.75V10.75C5.5 10.6119 5.38807 10.5 5.25 10.5Z' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1 9.5C1 8.94772 1.44772 8.5 2 8.5H6.5C7.05228 8.5 7.5 8.94772 7.5 9.5V14C7.5 14.5523 7.05228 15 6.5 15H2C1.44772 15 1 14.5523 1 14V9.5ZM6.5 9.5H2V14H6.5V9.5Z'
      />
    </svg>
  );
};

export { QRIcon };
