import React from 'react';
import { Link } from 'react-router-dom';

import { formatCoinsAmount } from '../../helpers';
import variables from '../../configs/variables';

const BlockTransactionTo = ({ outputs }) => {
  return (
    <ul>
      {outputs.map((output, index) => {
        const addressKey = output.verboseData.scriptPublicKeyAddress;
        const amount = output.amount / variables.coinParts;
        const formattedAmount = `${formatCoinsAmount(amount, true)} ${variables.coinSymbol}`;

        return (
          <li key={index} className='flex items-center overflow-hidden'>
            <Link className='link truncate' to={`/addresses/${addressKey}`}>
              {addressKey}
            </Link>
            <span className='ml-2 text-status-success'>{formattedAmount}</span>
          </li>
        );
      })}
    </ul>
  );
};

export { BlockTransactionTo };
