import React, { useCallback } from 'react';
import { useParams } from 'react-router';

import {
  BackButton,
  Card,
  ItemParam,
  SectionHeading,
  Spinner,
  TransactionAcceptingBlock,
  TransactionBlockHashes,
  TransactionDetails,
  TransactionInput,
  TransactionOutput,
} from '../components';
import { InputIcon, TransactionIcon } from '../icons';
import { useTransactionAdditionalInfo, useTransactionDetails } from '../hooks';
import { getTransactionTimestamp } from '../helpers';
import useBlueScore from '../contexts/bluescore-context';

const TxDetails = () => {
  const { id } = useParams();
  const { error, loading, transaction } = useTransactionDetails(id);
  const { info } = useTransactionAdditionalInfo(transaction);
  const blueScore = useBlueScore();

  const mass = transaction?.mass || '-';
  const timestamp = getTransactionTimestamp(transaction);
  const inputs = transaction?.inputs || [];
  const outputs = transaction?.outputs || [];

  const renderBlockHashes = useCallback(() => {
    return transaction ? (
      <TransactionBlockHashes blockHashes={transaction.block_hash} />
    ) : null;
  }, [transaction?.block_hash]);

  const isNotReadyToShowInputsOutputs =
    error || loading || (Object.keys(info).length === 0 && !!inputs.length);

  return (
    <>
      <BackButton />
      <Card>
        <TxDetails.Content>
          <SectionHeading icon={TransactionIcon} title='Transaction info' />
          <ItemParam title='Transaction ID' value={id} hasCopy />
          <ItemParam title='Subnetwork ID' value={transaction?.subnetwork_id} />
          <ItemParam title='Hash' value={transaction?.hash} />
          <ItemParam title='Mass' value={mass} />
          <ItemParam title='Block Hashes' value={renderBlockHashes()} />
          <ItemParam title='Block Time' value={timestamp} />
          <ItemParam
            title='Accepting Block Hash'
            value={
              <TransactionAcceptingBlock
                hash={transaction?.accepting_block_hash}
              />
            }
          />
          <ItemParam
            title='Details'
            value={
              <TransactionDetails
                isAccepted={transaction?.is_accepted}
                blueScore={blueScore}
                acceptingBlueScore={transaction?.accepting_block_blue_score}
              />
            }
          />
        </TxDetails.Content>
      </Card>

      {isNotReadyToShowInputsOutputs && <Spinner center />}
      {!isNotReadyToShowInputsOutputs && (
        <>
          <Card>
            <TxDetails.Content>
              <SectionHeading icon={InputIcon} title='Inputs' bold={false} />
              <div>
                {inputs.map((input) => (
                  <TransactionInput
                    key={input.previous_outpoint_hash}
                    info={info}
                    signatureOpCount={input.sig_op_count}
                    signatureScript={input.signature_script}
                    previousOutpointHash={input.previous_outpoint_hash}
                    previousOutpointIndex={+input.previous_outpoint_index}
                  />
                ))}
                {!inputs.length && (
                  <span className='text-neutral-7'>No data available</span>
                )}
              </div>
            </TxDetails.Content>
          </Card>

          <Card>
            <TxDetails.Content>
              <SectionHeading icon={InputIcon} title='Outputs' bold={false} />
              <div>
                {outputs.map((output) => (
                  <TransactionOutput
                    key={output.index}
                    index={output.index}
                    amount={output.amount}
                    publicKeyType={output.script_public_key_type}
                    publicKey={output.script_public_key}
                    publicKeyAddress={output.script_public_key_address}
                  />
                ))}
              </div>
            </TxDetails.Content>
          </Card>
        </>
      )}
    </>
  );
};

TxDetails.Content = ({ children }) => {
  return <div className='flex flex-col px-4 py-6'>{children}</div>;
};

export { TxDetails };
