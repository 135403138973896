import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const DEFAULT_PAGE = 1;
const PAGE_SEARCH_PARAM_NAME = 'page';

const usePaging = (amount, limit = 10) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(getInitialPage());

  const offset = (page - 1) * limit;

  const total = useMemo(() => {
    return Math.max(Math.ceil(amount / limit), 1);
  }, [amount, limit]);

  useEffect(() => {
    if (page <= 0 || (amount && page > total)) {
      setPage(DEFAULT_PAGE);
      return;
    }

    setSearchParams(
      { [PAGE_SEARCH_PARAM_NAME]: page },
      { replace: page === 1 },
    );
  }, [page, amount]);

  const onPrev = () => {
    if (page - 1 === 0) {
      return;
    }
    setPage((prev) => prev - 1);
  };

  const onNext = () => {
    if (page + 1 === total + 1) {
      return;
    }
    setPage((prev) => prev + 1);
  };

  function getInitialPage() {
    const hasPageInParams = searchParams.has(PAGE_SEARCH_PARAM_NAME);
    const pageInParams = +searchParams.get(PAGE_SEARCH_PARAM_NAME);
    const isPageInParamsANumber = !isNaN(pageInParams);

    return hasPageInParams && isPageInParamsANumber
      ? pageInParams
      : DEFAULT_PAGE;
  }

  return { page, total, offset, limit, onNext, onPrev };
};

export { usePaging };
