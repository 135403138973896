import { formatNumber } from './formatting';

const units = ['H/s', 'KH/s', 'MH/s', 'GH/s', 'TH/s', 'PH/s'];

const getHashRate = (difficulty) => {
  let unitIndex = 0;

  if (isNaN(difficulty)) {
    return `0 ${units[unitIndex]}`;
  }

  let hashRate = difficulty * 2;

  while (hashRate >= 1000 && unitIndex < units.length - 1) {
    hashRate /= 1000;
    unitIndex++;
  }

  return `${formatNumber(hashRate)} ${units[unitIndex]}`;
};

export { getHashRate };
