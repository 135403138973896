import React from 'react';
import { Link } from 'react-router-dom';

const TransactionAcceptingBlock = ({ hash }) => {
  if (!hash) {
    return '-';
  }

  return (
    <Link className='link' to={`/blocks/${hash}`}>
      {hash}
    </Link>
  );
};

export { TransactionAcceptingBlock };
