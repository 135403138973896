import { useEffect, useState } from 'react';
import ApiClient from '../services/api-client';

const useAddressTxs = (publicKey, offset, limit = 20) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [txs, setTxs] = useState([]);
  const [fullTxs, setFullTxs] = useState([]);

  useEffect(() => {
    fetchTxs();
  }, [publicKey, offset, limit]);

  const fetchTxs = async () => {
    try {
      setLoading(true);
      const _txs = await ApiClient.getAddressTransactions(
        publicKey,
        offset,
        limit,
      );

      const txsIds = filterUnique(getHashes(_txs));
      if (txsIds.length) {
        const _fullTxs = await ApiClient.getTransactionList(txsIds);
        const _fullTxsMap = getTxsMap(_fullTxs);
        setFullTxs(_fullTxsMap);
      }

      setTxs(_txs);
      setError(null);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  const filterUnique = (list) => {
    return [...new Set(list)];
  };

  const getHashes = (list) => {
    return list
      .flatMap((el) => el.inputs)
      .filter(Boolean)
      .map((el) => el.previous_outpoint_hash);
  };

  const getTxsMap = (list) => {
    return list.reduce((map, el) => {
      map[el.transaction_id] = el;
      return map;
    }, {});
  };

  return { txs, fullTxs, loading, error };
};

export { useAddressTxs };
