import React, { useEffect, useState } from 'react';

import { QRIcon } from '../../icons';
import { Modal } from '..';
import { useDisclosure } from '../../hooks';

const QRButton = ({ color = 'text-neutral-8', qrCode }) => {
  const { open, handleClose, handleOpen } = useDisclosure();
  const [container, setContainer] = useState(null);

  useEffect(() => {
    if (!qrCode || !open || !container) {
      return;
    }

    qrCode.append(container);
  }, [qrCode, container, open]);

  const showQRCode = () => {
    handleOpen();
  };

  return (
    <>
      <button
        onClick={showQRCode}
        className='button-hover rounded p-0.5 transition-colors'>
        <QRIcon color={color} />
      </button>
      <Modal open={open} onClose={handleClose}>
        <div className='p-4' ref={setContainer} />
      </Modal>
    </>
  );
};

export { QRButton };
