import React, { createContext, useContext } from 'react';

const BlocksContext = createContext({ blocks: [] });

const BlocksProvider = ({ children, blocks }) => {
  return (
    <BlocksContext.Provider value={{ blocks }}>
      {children}
    </BlocksContext.Provider>
  );
};

const useBlocks = () => {
  return useContext(BlocksContext);
};

export { BlocksProvider };
export default useBlocks;
