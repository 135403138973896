const getPercentStyle = (value) => {
  if (typeof value !== 'number') {
    return '';
  }

  if (value > 0) {
    return 'text-status-success';
  }
  if (value < 0) {
    return 'text-red-500';
  }

  return 'text-gray-400';
};

export { getPercentStyle };
