import React from 'react';
import clsx from 'clsx';

const Card = ({ children, className }) => {
  return (
    <div className={clsx('rounded-lg bg-white shadow-card', className)}>
      {children}
    </div>
  );
};

export { Card };
