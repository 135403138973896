import { io } from 'socket.io-client';
import variables from '../configs/variables';
import { useEffect, useState } from 'react';

const useSocket = () => {
  const [blocks, setBlocks] = useState([]);
  const [blueScore, setBlueScore] = useState(0);

  useEffect(() => {
    const socket = io(variables.socketBaseUrl, {
      path: '/ws/socket.io',
    });

    socket.on('connect', () => {
      console.warn('SOCKET CONNECTED');
    });

    socket.on('disconnect', () => {
      console.warn('SOCKET DISCONNECTED');
    });

    socket.on('last-blocks', (e) => {
      setBlocks(e);
    });

    socket.emit('join-room', 'blocks');

    socket.emit('last-blocks', '');

    socket.on('bluescore', (e) => {
      setBlueScore(+e.blueScore);
    });

    socket.emit('join-room', 'bluescore');

    socket.on('new-block', (e) => {
      setBlocks((prev) =>
        [e, ...prev].slice(0, 20).sort((a, b) => b.blueScore - a.blueScore),
      );
    });

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('last-blocks');
      socket.off('bluescore');
      socket.off('new-block');
      socket.close();
    };
  }, []);

  return { blocks, blueScore };
};

export { useSocket };
