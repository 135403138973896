import React from 'react';
import clsx from 'clsx';

const NetworkInfoIcon = ({ color, className }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      className={clsx('fill-current', color, className)}>
      <path d='M11.7183 15.324V17.3971L11.968 17.2342L12.2176 17.3971V15.324L11.968 15.487L11.7183 15.324Z' />
      <path d='M9.26403 19.8676L9.24655 19.8563L9.26403 19.8687V22.3932L11.7182 24V21.4757L9.26403 19.8638V19.8676Z' />
      <path d='M11.9679 17.8333L9.52121 19.4351L11.9679 21.042L14.4396 19.4477L11.9679 17.8333Z' />
      <path d='M12.2177 24L14.6769 22.3932V19.8939L12.2177 21.4781V24Z' />
      <path d='M3.6542 8.07189L1.20001 6.46002V8.98684L3.6542 10.5962V8.07189Z' />
      <path d='M6.36296 6.03385L3.90378 4.4295L1.45459 6.03134L3.90378 7.6382L6.36296 6.03385Z' />
      <path d='M4.15356 10.5962L6.61274 8.98685V6.46753L4.15356 8.0719V10.5962Z' />
      <path d='M17.3832 17.5851L19.8376 19.1939V16.6708L17.3832 15.0651V17.5851Z' />
      <path d='M20.3367 19.1939L22.7957 17.5851V15.076L20.3367 16.6719V19.1939Z' />
      <path d='M20.0871 13.0278L17.6344 14.631L20.0875 16.2357L22.553 14.6366L20.0871 13.0278Z' />
      <path d='M11.9678 8.55817L9.50421 10.1684L11.9684 11.7658L14.4543 10.1804L11.9678 8.55817Z' />
      <path d='M12.2177 14.7245L14.6769 13.1155V10.633L12.2177 12.2048V14.7245Z' />
      <path d='M11.7185 12.2019L9.26428 10.6096V13.1156L11.7185 14.7242V12.2019Z' />
      <path d='M12.218 6.16612L14.6766 4.5598V2.12024L12.218 3.65219V6.16612Z' />
      <path d='M14.4888 1.64681L11.9682 0L9.51898 1.60312L11.9688 3.20959L14.4888 1.64681Z' />
      <path d='M11.719 3.64425L9.26428 2.03491V4.55983L11.719 6.16658V3.64425Z' />
      <path d='M3.90375 13.0278L1.44537 14.6347L3.90485 16.2357L6.36415 14.6331L3.90375 13.0278Z' />
      <path d='M1.20001 17.5851L3.65517 19.1944V16.6715L1.20001 15.0725V17.5851Z' />
      <path d='M4.15445 19.1935L6.61265 17.5852V15.069L4.15445 16.6712V19.1935Z' />
      <path d='M3.65421 11.0976V12.5916L3.90387 12.4287L4.15353 12.5916V11.0976L3.90387 11.2605L3.65421 11.0976Z' />
      <path d='M19.8464 12.5916L20.0961 12.4287L20.3458 12.5916V11.0976L20.0961 11.2605L19.8464 11.0976V12.5916Z' />
      <path d='M16.8889 5.60013L15.1762 4.5799V4.83057L14.9115 5.00354L16.8889 6.18171V5.60013Z' />
      <path d='M6.89242 5.78061L7.11213 5.9235C7.11213 5.94874 7.11213 6.25958 7.11213 6.23434L9.04452 5.01353L8.7649 4.83054V4.59991C8.73976 4.61578 6.76702 5.8597 6.89242 5.78061Z' />
      <path d='M17.3345 18.1932C17.3322 18.1957 17.3326 18.1952 17.3345 18.1932V18.1932Z' />
      <path d='M17.3681 18.1717L16.9062 17.8708L14.894 19.1468L14.9039 19.1518L15.1761 19.3298V19.5604V19.5679C16.8404 18.5078 17.2639 18.238 17.3494 18.1836C17.3462 18.1856 17.3431 18.1876 17.3404 18.1893C17.3459 18.1858 17.3546 18.1803 17.3681 18.1717C17.3679 18.1718 17.3674 18.1721 17.367 18.1723C17.3673 18.1722 17.3679 18.1718 17.3681 18.1717Z' />
      <path d='M17.3682 18.1726C17.3706 18.1711 17.371 18.1707 17.3682 18.1726V18.1726Z' />
      <path d='M17.3406 18.1894C17.3377 18.1912 17.3357 18.1925 17.3345 18.1933C17.3359 18.1924 17.3379 18.1912 17.3406 18.1894Z' />
      <path d='M7.11207 17.8559L6.78751 18.069L8.64 19.4126L8.76484 19.5054V19.3324L9.06693 19.1344L9.0869 19.1193L7.11207 17.6829V17.8559Z' />
      <path d='M7.22008 3.98819L6.96359 3.55804L6.53519 3.81558L6.79168 4.24572L7.22008 3.98819Z' />
      <path d='M6.36315 4.5032L6.10666 4.07306L5.67826 4.33059L5.93475 4.76074L6.36315 4.5032Z' />
      <path d='M8.07701 3.47317L7.82053 3.04303L7.39204 3.30062L7.64853 3.73076L8.07701 3.47317Z' />
      <path d='M17.2638 3.81546L16.8354 3.55792L16.579 3.98807L17.0074 4.2456L17.2638 3.81546Z' />
      <path d='M18.1207 4.33047L17.6923 4.07294L17.4358 4.50308L17.8642 4.76062L18.1207 4.33047Z' />
      <path d='M16.4058 3.30014L15.9774 3.0426L15.7209 3.47275L16.1494 3.73028L16.4058 3.30014Z' />
      <path d='M17.1009 9.39794L16.8887 9.25756V8.94421C16.8436 8.96685 14.9356 9.92474 14.9613 9.91183L15.176 10.0522C15.176 10.0775 15.176 10.3908 15.176 10.3656L17.1009 9.39794Z' />
      <path d='M7.11197 9.25763L6.88977 9.40302L8.76474 10.3631C8.76474 10.3379 8.76474 10.0295 8.76474 10.0548L8.98194 9.9119C8.95685 9.89904 6.98626 8.88993 7.11197 8.95431V9.25763Z' />
      <path d='M19.8413 8.07189L17.3871 6.46002V8.98684L19.8413 10.5962V8.07189Z' />
      <path d='M20.0912 7.6382L22.5504 6.03385L20.0912 4.4295L17.642 6.03134L20.0912 7.6382Z' />
      <path d='M20.3408 8.0719V10.5962L22.8 8.98685V6.46753L20.3408 8.0719Z' />
    </svg>
  );
};

export { NetworkInfoIcon };
