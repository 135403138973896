const getQrCodeStyles = (data) => ({
  data,
  width: 300,
  height: 300,
  type: 'svg',
  image: '/logo.svg',
  dotsOptions: {
    color: '#203293',
    type: 'rounded',
  },
  cornersSquareOptions: {
    color: '#203293',
    type: 'extra-rounded',
  },
  imageOptions: {
    crossOrigin: 'anonymous',
    margin: 10,
    imageSize: 0.5,
  },
  backgroundOptions: {
    color: '#ffffff',
  },
  qrOptions: {
    typeNumber: 0,
  },
});

export { getQrCodeStyles };
