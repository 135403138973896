import variables from '../configs/variables';
import { formatDateTime } from './formatting';

const getAddressKeyFromOutputs = (outputs, index = 0) => {
  const address = getAddressFromOutputs(outputs, index);
  if (!address) {
    return 'unknown';
  }
  return address.script_public_key_address;
};

const getAmountFromOutputs = (outputs, index = 0) => {
  const address = getAddressFromOutputs(outputs, index);
  if (!address) {
    return 0;
  }
  return address.amount / variables.coinParts;
};

const getAddressFromOutputs = (outputs, index) => {
  if (!outputs) {
    return null;
  }
  return outputs.find((output) => output.index === index);
};

const getTransactionAmount = (tx) => {
  return tx.outputs.reduce((sum, output) => {
    const amount = output.amount || 0;
    return sum + amount / variables.coinParts;
  }, 0);
};

const getTransactionTimestamp = (tx) => {
  return tx ? formatDateTime(tx.block_time / 1000) : null;
};

const convertTxsArrayToMap = (txs) => {
  return txs.reduce((map, tx) => {
    map[tx.transaction_id] = tx;
    return map;
  }, {});
};

const getOutputByIndex = (outputs, index) => {
  if (!outputs) {
    return {};
  }
  return outputs.find((output) => output.index === index);
};

export {
  getAddressKeyFromOutputs,
  getAmountFromOutputs,
  getTransactionAmount,
  getTransactionTimestamp,
  convertTxsArrayToMap,
  getOutputByIndex,
};
