import { useEffect, useRef, useState } from 'react';

const useMount = (opened, delay = 300) => {
  const [mounted, setMounted] = useState(false);
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (opened && !mounted) {
      setMounted(true);
      return;
    }

    if (opened && mounted && timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
      return;
    }

    if (!opened && mounted) {
      timeoutRef.current = setTimeout(() => {
        setMounted(false);
      }, delay);
    }
  }, [opened]);

  return mounted;
};

export { useMount };
