import React from 'react';
import clsx from 'clsx';

const MarketDataIcon = ({ color, className }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      className={clsx('fill-current', color, className)}>
      <g clipPath='url(#clip0_178_450)'>
        <path d='M15.04 12.3871H12V23.2258H10.48V14.7097H7.43998V23.2258H5.91998V15.4839H2.87998V23.2258H1.35998V14.933L5.53998 10.6785L7.81998 13.001L14.28 6.42039L16.56 8.74297L21.5 3.71071L22.7513 4.98542L23.332 0.843484L19.266 1.43497L20.5173 2.70968L16.56 6.7409L14.28 4.41832L7.81998 10.999L5.53998 8.67639L1.35998 12.9345V9.29032H3.63998V8.51613H1.35998V7.74194H2.87998V6.96774H1.35998V6.19355H3.63998V5.41935H1.35998V4.64516H2.87998V3.87097H1.35998V3.09677H3.63998V2.32258H1.35998V1.54839H2.87998V0.774194H1.35998V0H0.599976V24H2.87998H5.91998H7.43998H10.48H12H15.04H16.56V23.2258H15.04V12.3871Z' />
        <path d='M22.798 17.8161L20.2638 14.0868C20.1346 13.8972 19.8253 13.8972 19.6961 14.0868L17.1619 17.8161C16.7682 18.3952 16.5599 19.0734 16.5599 19.7787C16.5599 21.6794 18.078 23.2258 19.9438 23.2258H20.0157C21.8818 23.2258 23.3999 21.6794 23.3999 19.7787C23.3999 19.0734 23.1917 18.3952 22.798 17.8161ZM19.5999 19.3548H20.3599C20.9885 19.3548 21.4999 19.8759 21.4999 20.5161V21.6774H20.3599V22.4516H19.5999V21.6774C18.9714 21.6774 18.4599 21.1564 18.4599 20.5161H19.2199C19.2199 20.7298 19.3902 20.9032 19.5999 20.9032H20.7399V20.5161C20.7399 20.3025 20.5697 20.129 20.3599 20.129H19.5999C18.9714 20.129 18.4599 19.608 18.4599 18.9677V17.8065H19.5999V17.0323H20.3599V17.8065C20.9885 17.8065 21.4999 18.3275 21.4999 18.9677H20.7399C20.7399 18.7541 20.5697 18.5806 20.3599 18.5806H19.2199V18.9677C19.2199 19.1814 19.3902 19.3548 19.5999 19.3548Z' />
      </g>
      <defs>
        <clipPath id='clip0_178_450'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export { MarketDataIcon };
