import React from 'react';
import { Link } from 'react-router-dom';

import { formatCoinsAmount } from '../../helpers';
import variables from '../../configs/variables';

const AddressTransactionTo = ({ outputs }) => {
  return (
    <ul>
      {outputs.map((output, index) => {
        const publicKey = output.script_public_key_address;

        return (
          <li key={index} className='flex items-center overflow-hidden'>
            <Link className='link truncate' to={`/addresses/${publicKey}`}>
              {publicKey}
            </Link>
            <span className='ml-2 text-status-success'>
              {formatCoinsAmount(output.amount / variables.coinParts, true)}
              &nbsp;{variables.coinSymbol}
            </span>
          </li>
        );
      })}
    </ul>
  );
};

export { AddressTransactionTo };
