import React from 'react';
import clsx from 'clsx';

const TransactionSimpleIcon = ({ color, className }) => {
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      className={clsx('fill-current', color, className)}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.59117 0.168133L0.169094 5.59071C-0.0563648 5.81619 -0.0563648 6.18299 0.169094 6.40845L5.59117 11.831C5.81663 12.0573 6.18339 12.0573 6.40883 11.831L11.8309 6.40845C12.0564 6.18217 12.0564 5.81617 11.8309 5.59071L6.40883 0.168133C6.18337 -0.0573466 5.81661 -0.0573466 5.59117 0.168133ZM7.04471 4.27429C6.82719 3.57722 5.7936 3.3589 5.21488 3.87971C4.56709 4.46325 5.05611 5.42708 6 5.42708C7.20903 5.42708 8.18875 6.33453 8.18875 7.45477C8.18875 8.43289 7.45205 9.21492 6.51689 9.4253V10.3018H5.40788V9.40625C4.71405 9.22523 4.12818 8.72347 3.90592 8.04229L4.95857 7.70011C5.18561 8.39401 6.21286 8.59963 6.78523 8.08357C7.43222 7.50004 6.944 6.53541 6.0001 6.53541C4.83472 6.53541 3.81136 5.66687 3.81136 4.50772C3.81136 3.55739 4.50836 2.78967 5.4078 2.55545V1.69404H6.51681V2.53797C7.25112 2.7039 7.87667 3.22392 8.10134 3.94323L7.04393 4.27271L7.04471 4.27429Z'
      />
    </svg>
  );
};

export { TransactionSimpleIcon };
