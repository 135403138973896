import React from 'react';

import {
  AcceptedIcon,
  ConfirmedIcon,
  ConfirmingIcon,
  NotAcceptedIcon,
} from '../../icons';
import variables from '../../configs/variables';

const AddressTransactionDetails = ({
  isAccepted,
  acceptingBlueScore,
  blueScore,
}) => {
  const fromBlueScore = Math.max(blueScore - acceptingBlueScore, 0);
  const isConfirmed =
    blueScore !== 0 && fromBlueScore >= variables.finalizedThreshold;

  return (
    <div className='col-span-2 flex gap-4'>
      {isAccepted && (
        <div className='flex items-center gap-1 font-semibold'>
          <AcceptedIcon color='text-status-success' />
          <span>Accepted</span>
        </div>
      )}
      {!isAccepted && (
        <div className='flex items-center gap-1 font-semibold'>
          <NotAcceptedIcon color='text-status-fail' />
          <span>Not accepted</span>
        </div>
      )}
      {acceptingBlueScore && isConfirmed && (
        <div className='flex items-center gap-1 font-semibold'>
          <ConfirmedIcon color='text-status-success' />
          <span>Confirmed</span>
        </div>
      )}
      {acceptingBlueScore && !!blueScore && !isConfirmed && (
        <div className='flex items-center gap-1 overflow-hidden font-semibold'>
          <ConfirmingIcon color='text-primary' />
          <span className='truncate'>{fromBlueScore} confirmations</span>
        </div>
      )}
    </div>
  );
};

export { AddressTransactionDetails };
