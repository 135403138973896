import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

const BlockChildren = ({ children }) => {
  const renderChildrenHash = useCallback((hash) => {
    return (
      <li className='flex items-center overflow-hidden' key={hash}>
        <Link className='link truncate' to={`/blocks/${hash}`}>
          {hash}
        </Link>
      </li>
    );
  }, []);

  return children.length ? (
    <ul>{children.map((hash) => renderChildrenHash(hash))}</ul>
  ) : (
    '-'
  );
};

export { BlockChildren };
