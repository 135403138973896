import React from 'react';

import { Spinner } from '..';
import { SearchIcon } from '../../icons';

const SearchButton = ({ loading }) => {
  return (
    <button
      disabled={loading}
      type='submit'
      className='flex aspect-square w-10 items-center justify-center rounded bg-lightBlue max-md:w-8'>
      {loading ? <Spinner /> : <SearchIcon color='text-primary' />}
    </button>
  );
};

export { SearchButton };
