const variables = {
  isTestnet: process.env.REACT_APP_IS_TESTNET === 'true' || true,
  coinSymbol: process.env.REACT_APP_COIN_SYMBOL || 'XxX',
  socketBaseUrl: process.env.REACT_APP_API_WS_URI || 'wss://api.kaspa.org/',
  apiBaseUrl: process.env.REACT_APP_API_URI || 'https://api.kaspa.org/',
  searchPlaceholder:
    process.env.REACT_APP_SEARCH_PLACEHOLDER || 'Search placeholder',
  statsCheckInterval: +process.env.REACT_APP_STATS_CHECK_INTERVAL || 60,
  coinParts: +process.env.REACT_APP_COIN_PARTS || 100_000_000,
  finalizedThreshold: +process.env.REACT_APP_FINALIZED_THRESHOLD || 86_400,
  coinBaseText: process.env.REACT_APP_COINBASE_TEXT || 'New coins',
  addressPrefix: process.env.REACT_APP_ADDRESS_PREFIX || 'kaspa',
  addressTxsLimit: +process.env.REACT_APP_ADDRESS_TXS_LIMIT || 10,
};

export default variables;
