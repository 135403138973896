import React from 'react';

import { ToTopIcon } from '../icons';
import { scrollToTop } from '../helpers';

const BackToTop = () => {
  return (
    <button
      onClick={() => scrollToTop('smooth')}
      className='flex items-center gap-2 self-end text-sm font-semibold text-link'>
      <ToTopIcon />
      <span>Back to top</span>
    </button>
  );
};

export { BackToTop };
