import React from 'react';
import { Link } from 'react-router-dom';

import {
  formatCoinsAmount,
  getAddressKeyFromOutputs,
  getAmountFromOutputs,
} from '../../helpers';
import variables from '../../configs/variables';

const BlockTransactionFrom = ({ inputs, allTransactions }) => {
  if (!inputs?.length) {
    return variables.coinBaseText;
  }

  return (
    <ul>
      {inputs.map((input, index) => {
        const transactionId = input.previousOutpoint.transactionId;
        const transactionIndex = input.previousOutpoint.index;

        const addressKey = getAddressKeyFromOutputs(
          allTransactions[transactionId].outputs,
          transactionIndex,
        );
        const amount = getAmountFromOutputs(
          allTransactions[transactionId].outputs,
          transactionIndex,
        );
        const formattedCoinsAmount = `${formatCoinsAmount(-amount, true)} ${variables.coinSymbol}`;

        return (
          <li className='flex items-center overflow-hidden' key={index}>
            <Link className='link truncate' to={`/addresses/${addressKey}`}>
              {addressKey}
            </Link>
            <span className='ml-2 text-status-fail'>
              {formattedCoinsAmount}
            </span>
          </li>
        );
      })}
    </ul>
  );
};

export { BlockTransactionFrom };
