import React from 'react';
import { Skeleton } from '../skeleton';

const BlockBlueScore = ({ blueScore }) => {
  return blueScore ? (
    <span className='text-neutral-7'>#{blueScore}</span>
  ) : (
    <Skeleton width={120} />
  );
};

export { BlockBlueScore };
