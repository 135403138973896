import React from 'react';

import { InfoCard } from '.';
import { NetworkInfoIcon } from '../../icons';
import useStats from '../../contexts/stats-context';

const NetworkInfoCard = () => {
  const { blockCount, headerCount, virtualDaaScore, hashrate, networkName } =
    useStats();

  return (
    <InfoCard
      icon={NetworkInfoIcon}
      title='Network info'
      params={{
        'Network name': networkName,
        'Block count': blockCount,
        'Header count': headerCount,
        'Global Block Index': virtualDaaScore,
        Hashrate: hashrate,
      }}
    />
  );
};

export { NetworkInfoCard };
