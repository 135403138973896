import React from 'react';

import { Skeleton } from '../skeleton';
import { TableCell, TableRow } from '../table';

const LatestRowSkeleton = ({ small, isTransaction }) => {
  if (small) {
    return <LatestRowSkeleton.Small />;
  }

  return <LatestRowSkeleton.Big isTransaction={isTransaction} />;
};

LatestRowSkeleton.Small = () => {
  return (
    <TableRow>
      <TableCell value={<Skeleton width={130} />} skeleton />
      <TableCell value={<Skeleton width={60} />} skeleton />
      <TableCell value={<Skeleton width='100%' />} last skeleton />
    </TableRow>
  );
};

LatestRowSkeleton.Big = ({ isTransaction }) => {
  return (
    <TableRow>
      <TableCell value={<Skeleton width={130} />} skeleton />
      {!isTransaction && (
        <TableCell value={<Skeleton width={230} />} skeleton />
      )}
      <TableCell value={<Skeleton width={80} skeleton />} />
      <TableCell value={<Skeleton width='100%' />} last skeleton />
    </TableRow>
  );
};

export { LatestRowSkeleton };
