import { convertTxsArrayToMap, getTransactionsIds } from '../helpers';
import { useEffect, useState } from 'react';
import ApiClient from '../services/api-client';

const useBlockTxs = (block) => {
  const txsIds = getTransactionsIds(block?.transactions);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [transactions, setTransactions] = useState({});

  useEffect(() => {
    if (!txsIds.length) {
      return;
    }
    fetchTxs();
  }, [block?.verboseData.hash, txsIds.length]);

  const fetchTxs = async () => {
    try {
      setLoading(true);
      const txs = await ApiClient.getTransactionList(txsIds);
      setTransactions(convertTxsArrayToMap(txs));
      setError(null);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  return { transactions, loading, error };
};

export { useBlockTxs };
