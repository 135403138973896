import React from 'react';
import { Link } from 'react-router-dom';

const TransactionBlockHashes = ({ blockHashes }) => {
  return (
    <ul>
      {blockHashes.map((hash) => (
        <li className='flex items-center overflow-hidden' key={hash}>
          <Link className='link truncate' to={`/blocks/${hash}`}>
            {hash}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export { TransactionBlockHashes };
