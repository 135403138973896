import React from 'react';

import {
  BlockTransactionDetails,
  BlockTransactionFrom,
  BlockTransactionId,
  BlockTransactionTo,
} from '.';
import { ItemParam } from '..';
import variables from '../../configs/variables';
import { formatCoinsAmount, formatUSD } from '../../helpers';

const BlockTransaction = ({
  id,
  amount,
  price,
  isAccepted,
  blueScore,
  acceptingBlueScore,
  inputs,
  outputs,
  allTransactions,
}) => {
  const value = formatUSD(amount * price);
  const formattedAmount = `${formatCoinsAmount(amount)} ${variables.coinSymbol}`;

  return (
    <div className='border-b-4 border-lightBlue py-3 first:pt-0 last:border-none last:pb-0'>
      <ItemParam
        title='Transaction ID'
        value={<BlockTransactionId id={id} />}
      />
      <ItemParam
        title='From'
        value={
          <BlockTransactionFrom
            inputs={inputs}
            allTransactions={allTransactions}
          />
        }
      />
      <ItemParam
        title='To'
        value={
          <BlockTransactionTo
            outputs={outputs}
            allTransactions={allTransactions}
          />
        }
      />
      <ItemParam title='TX Amount' value={formattedAmount} />
      <ItemParam title='TX Value' value={value} />
      <ItemParam
        title='Details'
        value={
          <BlockTransactionDetails
            blueScore={blueScore}
            acceptingBlueScore={acceptingBlueScore}
            isAccepted={isAccepted}
          />
        }
      />
    </div>
  );
};

export { BlockTransaction };
