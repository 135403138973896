import React from 'react';
import clsx from 'clsx';

const ConfirmedIcon = ({ color }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      className={clsx('fill-current', color)}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.1057 1.61201C7.32681 1.47536 7.60292 1.46293 7.83541 1.57918L9 2.16148L10.1646 1.57918C10.3759 1.47352 10.6247 1.47361 10.8359 1.57943L11.9994 2.16228L13.1822 1.57765C13.3937 1.47313 13.642 1.47418 13.8526 1.5805L15.0005 2.1601L16.153 1.58007C16.3647 1.47352 16.6143 1.4733 16.8262 1.57947L17.9887 2.16202L19.1669 1.57802C19.3776 1.4736 19.625 1.47402 19.8353 1.57914L21.0004 2.16148L22.1645 1.57922C22.397 1.46294 22.6731 1.47534 22.8943 1.61199C23.1154 1.74864 23.25 1.99005 23.25 2.25V12.75H21.75V3.4637L21.336 3.67078C21.1248 3.77638 20.8763 3.77641 20.6651 3.67086L19.4986 3.08777L18.32 3.67198C18.1091 3.77652 17.8613 3.77598 17.6509 3.67053L16.4909 3.08926L15.3372 3.66993C15.1248 3.77684 14.8742 3.77668 14.662 3.66951L13.511 3.08838L12.3295 3.67235C12.1188 3.77651 11.8714 3.77585 11.6613 3.67057L10.4997 3.08868L9.33541 3.67082C9.12426 3.77639 8.87574 3.77639 8.66459 3.67082L8.25 3.46353V15.75H6.75V2.25C6.75 1.99007 6.88459 1.74867 7.1057 1.61201Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.75 18V12.75H23.25V18C23.25 19.1935 22.7759 20.3381 21.932 21.182C21.0881 22.0259 19.9435 22.5 18.75 22.5H5.25C4.31406 22.5 3.52581 22.3473 2.87829 22.0216C2.21881 21.6898 1.74942 21.2006 1.42788 20.6074C0.816242 19.4792 0.750081 17.9777 0.749999 16.5028C0.748764 16.3057 0.786528 16.1104 0.86113 15.928C0.936222 15.7444 1.04713 15.5776 1.18739 15.4374C1.32764 15.2971 1.49443 15.1862 1.67802 15.1111C1.8604 15.0365 2.05573 14.9988 2.25275 15H15C15.4142 15 15.75 15.3358 15.75 15.75V18C15.75 18.7956 16.0661 19.5587 16.6287 20.1213C17.1913 20.6839 17.9543 21 18.75 21C19.5456 21 20.3087 20.6839 20.8713 20.1213C21.4339 19.5587 21.75 18.7956 21.75 18ZM15.3959 21C14.6599 20.1772 14.25 19.1097 14.25 18V16.5H2.25C2.25 18.0242 2.34211 19.1465 2.74657 19.8926C2.93327 20.2369 3.18675 20.4977 3.55234 20.6816C3.92989 20.8715 4.46891 21 5.25 21H15.3959Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.75 6.75C9.75 6.33579 10.0858 6 10.5 6H19.5C19.9142 6 20.25 6.33579 20.25 6.75C20.25 7.16421 19.9142 7.5 19.5 7.5H10.5C10.0858 7.5 9.75 7.16421 9.75 6.75ZM12.75 10.5C12.75 10.0858 13.0858 9.75 13.5 9.75H19.5C19.9142 9.75 20.25 10.0858 20.25 10.5C20.25 10.9142 19.9142 11.25 19.5 11.25H13.5C13.0858 11.25 12.75 10.9142 12.75 10.5Z'
      />
    </svg>
  );
};

export { ConfirmedIcon };
