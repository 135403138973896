import React from 'react';
import clsx from 'clsx';

const Skeleton = ({
  width = 100,
  height = 16,
  roundness = 'rounded',
  className,
}) => {
  return (
    <div
      className={clsx(roundness, className, 'animate-pulse bg-gray-200')}
      style={{
        width,
        height,
      }}></div>
  );
};

export { Skeleton };
