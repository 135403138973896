import React from 'react';

import { CopyButton } from '.';

const ValueWithCopy = ({ value, children }) => {
  return (
    <div className='flex w-full items-center gap-1'>
      <div className='truncate'>{children}</div>
      <CopyButton value={value} />
    </div>
  );
};

export { ValueWithCopy };
