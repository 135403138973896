import React from 'react';

import { CloseIcon, MenuIcon } from '../../icons';

const MenuButton = ({ open, onClick }) => {
  const Icon = open ? CloseIcon : MenuIcon;

  return (
    <button
      onClick={onClick}
      className='button-hover flex h-[24px] w-[24px] items-center justify-center rounded transition-colors'>
      <Icon color='text-primary' />
    </button>
  );
};

export { MenuButton };
