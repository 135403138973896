import React from 'react';
import clsx from 'clsx';

const MenuIcon = ({ color }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      className={clsx('stroke-current', color)}>
      <path
        d='M3.97485 5.97461H19.9748'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.97485 11.9746H19.9748'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.97485 17.9746H19.9748'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export { MenuIcon };
