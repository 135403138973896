import { useEffect, useState } from 'react';

import ApiClient from '../services/api-client';

const useTransactionDetails = (id) => {
  const [transaction, setTransaction] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchTx();
  }, [id]);

  const fetchTx = async () => {
    try {
      setLoading(true);

      const result = await ApiClient.getTransactionDetails(id);
      setTransaction(result);

      setError(null);
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  return { transaction, loading, error };
};

export { useTransactionDetails };
