import React from 'react';
import clsx from 'clsx';

const ArrowIcon = ({ color, className }) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      className={clsx('fill-current', color, className)}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.84467 2.97034C8.13756 2.67745 8.61244 2.67745 8.90533 2.97034L13.4053 7.47034C13.6982 7.76323 13.6982 8.23811 13.4053 8.531L8.90533 13.031C8.61244 13.3239 8.13756 13.3239 7.84467 13.031C7.55178 12.7381 7.55178 12.2632 7.84467 11.9703L11.0643 8.75067H3.125C2.71079 8.75067 2.375 8.41489 2.375 8.00067C2.375 7.58646 2.71079 7.25067 3.125 7.25067H11.0643L7.84467 4.031C7.55178 3.73811 7.55178 3.26323 7.84467 2.97034Z'
      />
    </svg>
  );
};

export { ArrowIcon };
