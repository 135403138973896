import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

const BlockParents = ({ parents }) => {
  const renderParentHash = useCallback(
    (hash) => {
      return (
        <li className='flex items-center overflow-hidden' key={hash}>
          <Link className='link truncate' to={`/blocks/${hash}`}>
            {hash}
          </Link>
        </li>
      );
    },
    [parents],
  );

  return parents.length ? (
    <ul>{parents.map((hash) => renderParentHash(hash))}</ul>
  ) : (
    '-'
  );
};

export { BlockParents };
