import React, { useEffect } from 'react';
import { useLocation } from 'react-router';

import { Collapse, Links, Logo, MenuButton } from '..';
import { useDisclosure } from '../../hooks';

const MobileNavigation = ({ isDynamicLogo }) => {
  const { open, handleToggle, handleClose } = useDisclosure();
  const { pathname } = useLocation();

  useEffect(() => {
    if (open) {
      handleClose();
    }
  }, [pathname]);

  return (
    <nav>
      <div className='flex h-16 items-center justify-between'>
        <Logo isDynamic={isDynamicLogo} />
        <MenuButton open={open} onClick={handleToggle} />
      </div>
      <Collapse open={open}>
        <div className='overflow-hidden'>
          <div className='flex flex-col pb-6 pt-3'>
            <Links />
          </div>
        </div>
      </Collapse>
    </nav>
  );
};

export { MobileNavigation };
