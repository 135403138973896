import React from 'react';

import variables from '../../configs/variables';

const TransactionDetails = ({ isAccepted, blueScore, acceptingBlueScore }) => {
  const fromBlueScore = Math.max(blueScore - acceptingBlueScore, 0);
  const isConfirmed =
    blueScore !== 0 && fromBlueScore >= variables.finalizedThreshold;

  return (
    <div className='flex gap-2'>
      {isAccepted && <span>Accepted</span>}
      {!isAccepted && <span>Not accepted</span>}
      {acceptingBlueScore && isConfirmed && (
        <span className='border-l border-black pl-2'>Confirmed</span>
      )}
      {acceptingBlueScore && !!blueScore && !isConfirmed && (
        <span className='border-l border-black pl-2'>
          {fromBlueScore} confirmations
        </span>
      )}
    </div>
  );
};

export { TransactionDetails };
