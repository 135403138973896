import React, { useMemo } from 'react';
import clsx from 'clsx';

import variables from '../configs/variables';
import usePrice from '../contexts/price-context';
import { formatPercentWithSign, formatUSD, getPercentStyle } from '../helpers';

const Price = () => {
  const { price, hourChange } = usePrice();
  const data = useMemo(
    () => [
      { value: variables.coinSymbol, className: 'text-primary' },
      { value: formatUSD(price), className: '' },
      {
        value: formatPercentWithSign(hourChange),
        className: getPercentStyle(hourChange),
      },
    ],
    [price, hourChange],
  );

  return (
    <div className='grid grid-cols-[auto_auto_auto] divide-x-2 divide-neutral-1 text-base font-semibold max-md:text-sm'>
      {data.map(({ value, className }) => (
        <div
          key={value}
          className={clsx(className, 'px-2 first:pl-0 last:pr-0')}>
          {value}
        </div>
      ))}
    </div>
  );
};

export { Price };
