import React from 'react';
import { Link } from 'react-router-dom';

const LinksSection = ({ title, links }) => {
  return (
    <div className='flex flex-col gap-1'>
      <span className='mb-4 font-medium'>{title}</span>
      {links.map(({ title, url }) => (
        <Link to={url} className='text-neutral-9' key={title}>
          {title}
        </Link>
      ))}
    </div>
  );
};

export { LinksSection };
