import clsx from 'clsx';

const activeLinkStyle = ({ isActive, isMobile }) => {
  return clsx({
    'font-medium': !isMobile,
    'border-b border-lightBlue px-4 py-2 active:bg-lightBlue transition-colors':
      isMobile,
    'font-semibold text-link': isActive && isMobile,
    'text-black': isActive && !isMobile,
    'text-neutral-7': !isActive && !isMobile,
  });
};

export default activeLinkStyle;
