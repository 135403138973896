import React from 'react';
import clsx from 'clsx';

import { PagingArrowIcon } from '../icons';

const Paging = ({ page, total, onNext, onPrev }) => {
  const isFirst = page === 1;
  const isLast = page === total;

  return (
    <div className='mt-6 border-t border-t-neutral-4 pt-6'>
      <div className='flex justify-end gap-1'>
        <Paging.Button disabled={isFirst} handler={onPrev}>
          <PagingArrowIcon className='rotate-180' />
        </Paging.Button>
        <div className='flex h-[32px] items-center justify-center rounded-lg border border-neutral-4 px-2 text-sm text-neutral-7'>
          Page {page} of {total}
        </div>
        <Paging.Button disabled={isLast} handler={onNext}>
          <PagingArrowIcon />
        </Paging.Button>
      </div>
    </div>
  );
};

Paging.Button = ({ children, handler, disabled }) => {
  return (
    <button
      onClick={handler}
      disabled={disabled}
      className={clsx(
        { '!text-neutral-8': disabled },
        'flex h-[32px] w-[32px] items-center justify-center rounded-lg border border-neutral-4 text-primary',
      )}>
      {children}
    </button>
  );
};

export { Paging };
