import React, { useMemo } from 'react';

import {
  BackToTop,
  CoinSupplyInfoCard,
  Content,
  HomeLogo,
  LatestList,
  MainWrapper,
  MarketDataInfoCard,
  NetworkInfoCard,
  Price,
  SearchBar,
  ServerInfoCard,
} from '../components';
import { getArtworkStyles, getTransactionsFromBlocks } from '../helpers';
import { BlockIcon, TransactionIcon } from '../icons';
import useBlocks from '../contexts/blocks-context';
import { useMobileView } from '../hooks';

const Home = () => {
  const { blocks } = useBlocks();
  const isMobile = useMobileView();

  const transactions = useMemo(
    () => getTransactionsFromBlocks(blocks),
    [blocks],
  );

  return (
    <>
      <Home.Heading />
      <MainWrapper>
        <Content>
          {isMobile && (
            <div className='-my-2 flex justify-center'>
              <Price />
            </div>
          )}
          <div className='grid grid-cols-4 gap-4 max-xl:grid-cols-2 max-md:grid-cols-1 max-md:gap-3'>
            <CoinSupplyInfoCard />
            <NetworkInfoCard />
            <ServerInfoCard />
            <MarketDataInfoCard />
          </div>
          <div className='grid grid-cols-2 gap-4 max-md:grid-cols-1'>
            <LatestList
              icon={BlockIcon}
              title='Latest blocks'
              url='/blocks'
              name='blocks'
              rowTitles={['Timestamp', 'TXs', 'Hash']}
              data={blocks}
              small
            />
            <LatestList
              icon={TransactionIcon}
              title='Latest transactions'
              url='/txs'
              name='transactions'
              rowTitles={['ID', 'Amount', 'Recipient']}
              data={transactions}
              small
            />
          </div>
          {isMobile && <BackToTop />}
        </Content>
      </MainWrapper>
    </>
  );
};

Home.Heading = () => {
  return (
    <div
      className='flex h-[268px] items-center bg-primary max-md:h-[160px]'
      style={getArtworkStyles()}>
      <MainWrapper>
        <div className='flex h-full flex-col justify-center gap-10 max-md:gap-4'>
          <HomeLogo />
          <SearchBar />
        </div>
      </MainWrapper>
    </div>
  );
};

export { Home };
