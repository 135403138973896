import React from 'react';
import { Outlet, useLocation } from 'react-router';

import {
  BackToTop,
  Content,
  Footer,
  Header,
  MainWrapper,
  ScrollToTop,
  Search,
} from '..';
import { useMobileView } from '../../hooks';

const Layout = () => {
  const { pathname } = useLocation();
  const isHome = pathname === '/';
  const isSearch = pathname === '/search';
  const isMobile = useMobileView();

  return (
    <>
      <ScrollToTop />
      <Header isDynamicLogo={!isHome} />
      <main className='flex flex-1 flex-col pt-desktop-header max-md:pt-mobile-header'>
        {!isHome ? <Search /> : null}
        {isHome ? (
          <Outlet />
        ) : (
          <MainWrapper>
            <Content>
              <Outlet />
              {isMobile && !isSearch && <BackToTop />}
            </Content>
          </MainWrapper>
        )}
      </main>
      <Footer />
    </>
  );
};

export { Layout };
