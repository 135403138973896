const artworkStyles = {
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
};

const getArtworkStyles = (small = false) => {
  return {
    ...artworkStyles,
    backgroundImage: small ? 'url(/artwork_small.png)' : 'url(/artwork.png)',
  };
};

export { getArtworkStyles };
