import React from 'react';
import { createContext, useContext } from 'react';

const BlueScoreContext = createContext(0);

const BlueScoreProvider = ({ children, blueScore }) => {
  return (
    <BlueScoreContext.Provider value={blueScore}>
      {children}
    </BlueScoreContext.Provider>
  );
};

const useBlueScore = () => {
  return useContext(BlueScoreContext);
};

export { BlueScoreProvider };
export default useBlueScore;
