import React from 'react';
import { CopyButton, Skeleton } from '.';

const ItemParam = ({ title, value, hasCopy = false }) => {
  return (
    <div className='flex gap-6 border-b border-lightBlue py-3 last:border-none max-md:flex-col max-md:gap-2 md:items-center'>
      <span className='w-[200px] shrink-0 self-start font-semibold'>
        {title}
      </span>
      {!!value && (
        <div className='flex items-center gap-2 overflow-hidden'>
          <div className='truncate'>{value}</div>
          {hasCopy && <CopyButton value={value} />}
        </div>
      )}
      {!value && <Skeleton width='50%' />}
    </div>
  );
};

export { ItemParam };
