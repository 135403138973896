import React from 'react';
import { RouterProvider } from 'react-router-dom';

import {
  BlocksProvider,
  BlueScoreProvider,
  PriceProvider,
  StatsProvider,
} from './contexts';
import { useSocket } from './hooks';
import router from './router';

const App = () => {
  const { blocks, blueScore } = useSocket();

  return (
    <PriceProvider>
      <StatsProvider>
        <BlocksProvider blocks={blocks}>
          <BlueScoreProvider blueScore={blueScore}>
            <RouterProvider router={router} />
          </BlueScoreProvider>
        </BlocksProvider>
      </StatsProvider>
    </PriceProvider>
  );
};

export default App;
