import { useState } from 'react';

const useDisclosure = (defaultOpen = false) => {
  const [open, setOpen] = useState(defaultOpen);

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const handleToggle = () => setOpen((prev) => !prev);

  return { open, handleClose, handleOpen, handleToggle };
};

export { useDisclosure };
