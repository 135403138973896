const getTransactionsFromBlocks = (blocks) => {
  return blocks
    .sort((a, b) => b.blueScore - a.blueScore)
    .flatMap((block) =>
      block.txs.flatMap((tx) =>
        tx.outputs.flatMap((output, outputIndex) => {
          return {
            amount: output[1],
            address: output[0],
            txId: tx.txId,
            outputIndex,
          };
        }),
      ),
    )
    .filter(
      (v, i, a) =>
        a.findIndex((v2) => JSON.stringify(v) === JSON.stringify(v2)) === i,
    );
};

export { getTransactionsFromBlocks };
