import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';

import {
  AddressDetails,
  BlockDetails,
  Blocks,
  Home,
  NotFound,
  Search,
  TxDetails,
  Txs,
} from './pages';
import { Layout } from './components';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      { index: true, element: <Home /> },
      { path: 'blocks', element: <Blocks /> },
      { path: 'blocks/:hash', element: <BlockDetails /> },
      { path: 'txs', element: <Txs /> },
      { path: 'txs/:id', element: <TxDetails /> },
      { path: 'addresses/:address', element: <AddressDetails /> },
      { path: 'search', element: <Search /> },
    ],
  },
  { path: 'not-found', element: <NotFound /> },
  { path: '*', element: <Navigate to='not-found' replace /> },
]);

export default router;
