import React from 'react';

const SectionIcon = ({ icon }) => {
  const Icon = icon;

  return (
    <div className='flex h-[24px] w-[24px] items-center justify-center rounded bg-lightBlue p-1'>
      <Icon color='text-primary' />
    </div>
  );
};

export { SectionIcon };
