import React from 'react';

import variables from '../../configs/variables';

const BlockTransactionDetails = ({
  isAccepted,
  blueScore,
  acceptingBlueScore,
}) => {
  const fromBlueScore = Math.max(blueScore - acceptingBlueScore, 0);
  const isFinalized =
    blueScore !== 0 && fromBlueScore >= variables.finalizedThreshold;

  return (
    <div className='flex flex-col'>
      {isAccepted && <span>Accepted</span>}
      {!isAccepted && <span>Not Accepted</span>}
      {acceptingBlueScore && isFinalized && <span>Finalized</span>}
      {acceptingBlueScore && !!blueScore && !isFinalized && (
        <span>{fromBlueScore} confirmations</span>
      )}
    </div>
  );
};

export { BlockTransactionDetails };
