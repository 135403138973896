/* global BigInt */

import { parsePayload } from './parse-transaction-payload';
import { formatDateTime } from './formatting';

const getBlockMinerInfo = (block) => {
  const [minerName, minerAddress] = parsePayload(
    block?.transactions[0]?.payload,
  );

  return { minerName, minerAddress };
};

const getBlockParentsHashes = (block) => {
  return block?.header.parents[0]?.parentHashes || [];
};

const getBlockChildrenHashes = (block) => {
  return block?.verboseData.childrenHashes || [];
};

const getBlockInChainStatus = (block) => {
  return block ? (block?.verboseData.isChainBlock ? 'True' : 'False') : null;
};

const getBlockTimestamp = (block) => {
  return block ? formatDateTime(+block.header.timestamp / 1000) : null;
};

const getBlockBlueWork = (block) => {
  if (!block) {
    return null;
  }

  const blueWork = block?.header.blueWork;
  const blueWorkBigInt = BigInt(`0x${blueWork}`);

  return `${blueWork} (${blueWorkBigInt})`;
};

export {
  getBlockMinerInfo,
  getBlockChildrenHashes,
  getBlockTimestamp,
  getBlockParentsHashes,
  getBlockInChainStatus,
  getBlockBlueWork,
};
