import React from 'react';

import { BlockIcon } from '../../icons';

const HomeLogo = () => {
  return (
    <div className='relative h-[60px] max-md:h-[40px]'>
      <BlockIcon
        color='text-[#3482EB]'
        className='logo-cube-shadow absolute -left-[4px] -top-[8px] h-[32px] w-[32px] animate-cube max-md:-left-[3px] max-md:-top-[6px] max-md:h-[22px] max-md:w-[22px]'
      />
      <img src='home_logo.svg' alt='logo' className='h-full' />
    </div>
  );
};

export { HomeLogo };
