import React from 'react';
import { Link } from 'react-router-dom';

import { CoinAmount, TwoLineParam, ValueWithCopy } from '..';
import variables from '../../configs/variables';

const TransactionOutput = ({
  index,
  publicKeyType,
  amount,
  publicKey,
  publicKeyAddress,
}) => {
  return (
    <div className='border-b-4 border-lightBlue py-3 first:pt-0 last:border-none last:pb-0'>
      <div className='grid grid-cols-[auto_auto_minmax(auto,50%)] gap-8 max-md:grid-cols-1'>
        <TwoLineParam
          title='Amount'
          value={
            <CoinAmount
              amount={amount / variables.coinParts}
              bg='bg-status-success/10'
              color='text-status-success'
            />
          }
        />
        <TwoLineParam title='Index' value={`#${index}`} />
        <TwoLineParam title='Secret Public Key Type' value={publicKeyType} />
        <TwoLineParam
          title='Script Public Key'
          value={<ValueWithCopy value={publicKey}>{publicKey}</ValueWithCopy>}
          className='md:col-span-3'
        />
        <TwoLineParam
          title='Script Public Key Address'
          value={
            <ValueWithCopy value={publicKeyAddress}>
              <Link className='link' to={`/addresses/${publicKeyAddress}`}>
                {publicKeyAddress}
              </Link>
            </ValueWithCopy>
          }
          className='md:col-span-3'
        />
      </div>
    </div>
  );
};

export { TransactionOutput };
