const links = [
  {
    url: '/',
    name: 'Home',
  },
  {
    url: '/blocks',
    name: 'Blocks',
  },
  {
    url: '/txs',
    name: 'Transactions',
  },
];

export default links;
