import React from 'react';
import clsx from 'clsx';

const TwitterIcon = ({ color = '' }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      className={clsx('fill-current', color)}>
      <path d='M18.205 2.25H21.513L14.286 10.51L22.788 21.75H16.13L10.916 14.933L4.94997 21.75H1.63997L9.36997 12.915L1.21497 2.25H8.03997L12.753 8.481L18.205 2.25ZM17.044 19.77H18.877L7.04497 4.126H5.07797L17.044 19.77Z' />
    </svg>
  );
};

export { TwitterIcon };
