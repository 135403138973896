import React from 'react';
import { ArrowIcon } from '../../icons';
import { useNavigate } from 'react-router';

const BackButton = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <button
      onClick={goBack}
      className='flex w-fit items-center gap-2 font-semibold text-primary max-md:-my-2'>
      <ArrowIcon className='rotate-180' />
      <span>Back</span>
    </button>
  );
};

export { BackButton };
