import React from 'react';
import clsx from 'clsx';

const InputIcon = ({ color }) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      className={clsx('fill-current', color)}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.5 2C3.94772 2 3.5 2.44772 3.5 3V13C3.5 13.5523 3.94772 14 4.5 14H11.5C12.0523 14 12.5 13.5523 12.5 13V3C12.5 2.44772 12.0523 2 11.5 2H4.5ZM2.5 3C2.5 1.89543 3.39543 1 4.5 1H11.5C12.6046 1 13.5 1.89543 13.5 3V13C13.5 14.1046 12.6046 15 11.5 15H4.5C3.39543 15 2.5 14.1046 2.5 13V3Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5 4C5 3.72386 5.22386 3.5 5.5 3.5H10.5C10.7761 3.5 11 3.72386 11 4C11 4.27614 10.7761 4.5 10.5 4.5H5.5C5.22386 4.5 5 4.27614 5 4ZM5 6.5C5 6.22386 5.22386 6 5.5 6H10.5C10.7761 6 11 6.22386 11 6.5C11 6.77614 10.7761 7 10.5 7H5.5C5.22386 7 5 6.77614 5 6.5ZM5 9C5 8.72386 5.22386 8.5 5.5 8.5H8C8.27614 8.5 8.5 8.72386 8.5 9C8.5 9.27614 8.27614 9.5 8 9.5H5.5C5.22386 9.5 5 9.27614 5 9Z'
      />
    </svg>
  );
};

export { InputIcon };
