import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

import { LatestBlockRow, LatestRowSkeleton, LatestTransactionRow } from '.';
import { Card, Table } from '..';
import { ArrowIcon } from '../../icons';

const LatestList = ({
  icon,
  title,
  name,
  data,
  url,
  rowTitles,
  lines = 10,
  small = false,
}) => {
  const isTransaction = name === 'transactions';

  const renderRow = useCallback((el) => {
    switch (name) {
      case 'blocks':
        return <LatestBlockRow key={el.block_hash} block={el} small={small} />;
      case 'transactions':
        return (
          <LatestTransactionRow
            key={el.address + el.txId + el.outputIndex}
            transaction={el}
            small={small}
          />
        );
    }
  }, []);

  const renderSkeleton = useCallback(
    () =>
      [...Array(lines)].map((_, i) => (
        <LatestRowSkeleton
          key={i}
          small={small}
          isTransaction={isTransaction}
        />
      )),
    [],
  );

  return (
    <Card className='flex flex-col'>
      {small ? (
        <LatestList.Heading
          icon={icon}
          title={title}
          isTransaction={isTransaction}
        />
      ) : null}
      <LatestList.Body
        titles={rowTitles}
        data={data.slice(0, lines)}
        renderRow={renderRow}
        renderSkeleton={renderSkeleton}
        small={small}
        isTransaction={isTransaction}
      />
      {small ? <LatestList.Footer name={name} url={url} /> : null}
    </Card>
  );
};

LatestList.Heading = ({ icon, title, isTransaction }) => {
  const Icon = icon;
  return (
    <div className='flex items-center gap-4 border-b border-lightBlue bg-lightBlue p-4'>
      <Icon
        color='text-primary'
        className={isTransaction ? 'animate-tx-spin' : 'animate-block-spin'}
      />
      <span className='font-semibold'>{title}</span>
    </div>
  );
};

LatestList.Body = ({
  data,
  titles,
  renderRow,
  renderSkeleton,
  isTransaction,
  small,
}) => {
  const cols = small ? 3 : isTransaction ? 3 : 4;

  return (
    <div className='overflow-hidden p-4 max-md:p-3'>
      <Table
        titles={titles}
        rows={data}
        renderRow={renderRow}
        renderSkeleton={renderSkeleton}
        cols={cols}
      />
    </div>
  );
};

LatestList.Footer = ({ name, url }) => {
  return (
    <div className='mt-auto flex p-4 pt-0 text-sm font-semibold text-link max-md:justify-center'>
      <Link to={url} className='flex items-center gap-1.5'>
        <span>View all {name}</span>
        <ArrowIcon />
      </Link>
    </div>
  );
};

export { LatestList };
