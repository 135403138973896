import React from 'react';

import { MainWrapper } from './wrappers';
import { SearchBar } from '.';
import { getArtworkStyles } from '../helpers';

const Search = () => {
  return (
    <div className='h-[160px] bg-primary' style={getArtworkStyles(true)}>
      <MainWrapper>
        <div className='flex h-full items-center justify-center'>
          <SearchBar />
        </div>
      </MainWrapper>
    </div>
  );
};

export { Search };
