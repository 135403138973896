import React from 'react';
import clsx from 'clsx';

import { formatCoinsAmount } from '../helpers';
import variables from '../configs/variables';

const CoinAmount = ({
  amount,
  color = 'text-primary',
  bg = 'bg-lightBlue',
}) => {
  return (
    <div
      className={clsx(
        'w-fit rounded-md p-1.5 px-2 text-xs font-medium',
        color,
        bg,
      )}>
      {formatCoinsAmount(amount, true)}&nbsp;
      {variables.coinSymbol}
    </div>
  );
};

export { CoinAmount };
