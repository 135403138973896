import React from 'react';

import { MainWrapper } from '../wrappers';
import { LinksSection, Logo } from '..';
import { Socials } from '.';

const contacts = [
  { title: '(250) 555-0199', url: 'tel:+12505550199' },
  { title: 'impauer@gmail.com', url: 'mailto:impauer@gmail.com' },
];

const other = [
  { title: 'Global', url: '/' },
  { title: 'Local', url: '/' },
];

const Footer = () => {
  return (
    <footer className='bg-primary py-8 text-white'>
      <MainWrapper>
        <div className='flex h-full items-center'>
          <div className='grid flex-1 grid-cols-3 gap-y-9 max-md:grid-cols-1'>
            <div className='flex flex-1 flex-col justify-between gap-6'>
              <Logo white />
              <Socials />
            </div>
            <LinksSection title='Contacts' links={contacts} />
            <LinksSection title='Other' links={other} />
          </div>
        </div>
      </MainWrapper>
    </footer>
  );
};

export { Footer };
