import React, { useState } from 'react';

import variables from '../configs/variables';
import { SearchButton } from '.';
import { useSearch } from '../hooks';

const SearchBar = ({ width = '100%' }) => {
  const [value, setValue] = useState('');
  const { onSearch, loading } = useSearch(clear);
  const maxWidth = width;

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    onSearch(value.trim());
  };

  function clear() {
    setValue('');
  }

  return (
    <form
      style={{ maxWidth, width: '100%' }}
      className='relative'
      onSubmit={handleSearch}>
      <input
        value={value}
        onChange={onChange}
        placeholder={variables.searchPlaceholder}
        type='text'
        className='h-12 w-full rounded-lg px-4 pr-[60px] outline-0 placeholder-shown:truncate max-md:h-10 max-md:text-sm'
      />
      <div className='absolute right-1 top-1'>
        <SearchButton loading={loading} />
      </div>
    </form>
  );
};

export { SearchBar };
