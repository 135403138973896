import React from 'react';

import { formatDateTime } from '../../helpers';

const AddressTransactionTime = ({ timestamp }) => {
  return (
    <span className='col-span-2 text-primary'>
      {formatDateTime(timestamp / 1000)}
    </span>
  );
};

export { AddressTransactionTime };
