import React from 'react';
import clsx from 'clsx';

import { useDisclosure } from '../../hooks';
import { CollapseArrowIcon } from '../../icons';
import { Card, Collapse } from '..';

const InfoCard = ({ icon, title, params }) => {
  const { open, handleToggle } = useDisclosure();

  const Icon = icon;

  const cardClassName = clsx(
    { 'shadow-card-hover !bg-interaction-active-1 text-link': open },
    'hover:shadow-card-hover active:shadow-card-hover group transition-[color,box-shadow,background-color] hover:text-link active:text-link',
  );
  const iconClassName = clsx(
    { '!text-link': open },
    'transition-colors group-hover:text-link group-active:text-link',
  );

  return (
    <div>
      <Card className={cardClassName}>
        <button
          onClick={handleToggle}
          className='flex w-full items-center justify-between p-5 max-md:p-4'>
          <div className='flex items-center gap-3'>
            <Icon color='text-primary' className={iconClassName} />
            <span className='font-semibold'>{title}</span>
          </div>
          <CollapseArrowIcon
            color='text-primary'
            className={clsx({ 'rotate-180': open }, 'transition-transform')}
          />
        </button>
      </Card>
      <Collapse open={open}>
        <InfoCard.Content params={params} open={open} />
      </Collapse>
    </div>
  );
};

InfoCard.Content = ({ params, open }) => {
  return (
    <Card
      className={clsx(
        { 'mt-2': open, 'delay-150': !open },
        'overflow-hidden !transition-all',
      )}>
      <div className='p-4'>
        <div className='grid w-full grid-cols-[auto_auto] gap-x-4 gap-y-1'>
          {Object.entries(params).map(([title, value]) => (
            <div key={title} className='contents'>
              <div
                className={clsx({ 'font-semibold': !title.includes('%') }, '')}>
                {title}
              </div>
              <div>{value}</div>
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
};

export { InfoCard };
