import React from 'react';
import { TendArrowIcon } from '../icons';
import clsx from 'clsx';
import { formatPercentWithSign } from '../helpers';

const TendPercent = ({ value }) => {
  return (
    <div className='flex items-center gap-0.5'>
      {value !== 0 && (
        <TendArrowIcon
          color={clsx(
            value > 0 ? 'text-status-success' : 'rotate-180 text-status-fail',
          )}
        />
      )}
      {formatPercentWithSign(value)}
    </div>
  );
};

export { TendPercent };
