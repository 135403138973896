import { format } from 'date-fns';

const usdFormatter = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 4,
  minimumFractionDigits: 2,
});

const bigUsdFormatter = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
});

const percentFormatterWithSign = Intl.NumberFormat('en-US', {
  style: 'unit',
  unit: 'percent',
  signDisplay: 'negative',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const percentFormatter = Intl.NumberFormat('en-US', {
  style: 'unit',
  unit: 'percent',
  signDisplay: 'never',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const bigNumberFormatter = Intl.NumberFormat('en-US', {
  maximumFractionDigits: 0,
});

const numberFormatter = Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

const formatUSD = (value) => {
  if (isNaN(value)) {
    return 'NaN';
  }

  return usdFormatter.format(value);
};

const formatPercentWithSign = (value) => {
  if (isNaN(value)) {
    return 'NaN';
  }

  return percentFormatterWithSign.format(value);
};

const formatPercent = (value) => {
  if (isNaN(value)) {
    return 'NaN';
  }

  return percentFormatter.format(value);
};

const formatBigNumber = (value) => {
  if (isNaN(value)) {
    return 'NaN';
  }

  return bigNumberFormatter.format(value);
};

const formatNumber = (value) => {
  if (isNaN(value)) {
    return 'NaN';
  }

  return numberFormatter.format(value);
};

const formatDateTime = (value) => {
  if (isNaN(value)) {
    return 'NaN';
  }

  return format(value * 1000, 'yyyy-MM-dd HH:mm:ss');
};

const formatBigUSD = (value) => {
  if (isNaN(value)) {
    return 'NaN';
  }

  return bigUsdFormatter.format(value);
};

const formatCoinsAmount = (value, sign = false) => {
  if (isNaN(value)) {
    return 'NaN';
  }

  return Intl.NumberFormat('en-US', {
    maximumFractionDigits: 9,
    minimumFractionDigits: 0,
    signDisplay: sign ? 'always' : 'never',
  }).format(value);
};

export {
  formatUSD,
  formatBigUSD,
  formatPercentWithSign,
  formatBigNumber,
  formatPercent,
  formatNumber,
  formatDateTime,
  formatCoinsAmount,
};
