import React from 'react';
import clsx from 'clsx';

const TableRow = ({ children, onClick }) => {
  return (
    <div className={clsx('group/row contents')} onClick={onClick}>
      {children}
    </div>
  );
};

export { TableRow };
