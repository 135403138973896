import React from 'react';
import { useNavigate } from 'react-router';

import { formatDateTime } from '../../helpers';
import { SectionIcon, TableRow } from '..';
import { BlockIcon } from '../../icons';
import { TableCell } from '../table';

const LatestBlockRow = ({ block, small }) => {
  const navigate = useNavigate();

  const goToDetails = () => {
    navigate(`/blocks/${block.block_hash}`);
  };

  const props = {
    timestamp: block.timestamp,
    blockHash: block.block_hash,
    txCount: block.txCount,
    goToDetails: goToDetails,
  };

  if (small) {
    return <LatestBlockRow.Small {...props} />;
  }

  return <LatestBlockRow.Big blueScore={block.blueScore} {...props} />;
};

LatestBlockRow.Small = ({ timestamp, txCount, blockHash, goToDetails }) => {
  return (
    <TableRow onClick={goToDetails}>
      <TableCell
        title='Timestamp'
        value={
          <div className='flex items-center gap-2'>
            <BlockIcon color='text-link' className='h-[12px]' />
            {formatDateTime(+timestamp / 1000)}
          </div>
        }
        className='table-cell-interaction text-neutral-7'
      />
      <TableCell
        title='TXs'
        value={txCount}
        className='table-cell-interaction'
      />
      <TableCell
        title='Hash'
        value={blockHash}
        className='table-cell-interaction'
        truncate
        last
      />
    </TableRow>
  );
};

LatestBlockRow.Big = ({
  blueScore,
  timestamp,
  txCount,
  blockHash,
  goToDetails,
}) => {
  return (
    <TableRow onClick={goToDetails}>
      <TableCell
        title='Block Index'
        value={
          <div className='flex items-center gap-2'>
            <SectionIcon icon={BlockIcon} />
            <span className='text-primary group-hover/row:text-link group-active/row:text-link'>
              {blueScore}
            </span>
          </div>
        }
        className='table-cell-interaction'
      />
      <TableCell
        title='Timestamp'
        value={formatDateTime(+timestamp / 1000)}
        className='table-cell-interaction text-neutral-7'
      />
      <TableCell
        title='TXs'
        value={txCount}
        className='table-cell-interaction'
      />
      <TableCell
        title='Hash'
        value={blockHash}
        className='table-cell-interaction'
        truncate
        last
      />
    </TableRow>
  );
};

export { LatestBlockRow };
