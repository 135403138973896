import React from 'react';
import clsx from 'clsx';

const Collapse = ({ open, children }) => {
  return (
    <div
      className={clsx(
        { 'grid-rows-[0fr]': !open, 'grid-rows-[1fr]': open },
        'grid transition-[grid-template-rows] duration-300',
      )}>
      {children}
    </div>
  );
};

export { Collapse };
