import React from 'react';
import { NavLink } from 'react-router-dom';

import navLinks from '../../helpers/nav-links';
import activeLinkStyles from '../../helpers/active-link-styles';
import { useMobileView } from '../../hooks';

const Links = () => {
  const isMobile = useMobileView();

  return (
    <>
      {navLinks.map(({ name, url }) => (
        <NavLink
          to={url}
          key={url}
          className={({ isActive }) =>
            activeLinkStyles({ isActive, isMobile })
          }>
          {name}
        </NavLink>
      ))}
    </>
  );
};

export { Links };
