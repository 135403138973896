import React, { useCallback } from 'react';

import { InfoCard } from './InfoCard';
import { MarketDataIcon } from '../../icons';
import usePrice from '../../contexts/price-context';
import useStats from '../../contexts/stats-context';
import variables from '../../configs/variables';
import { formatBigUSD, formatUSD } from '../../helpers';
import { TendPercent } from '../TendPercent';

const BILLION = 1_000_000_000;

const MarketDataInfoCard = () => {
  const { price, hourChange, dayChange, weekChange, volume, rank } = usePrice();
  const { totalSupply } = useStats();

  const renderMarketCap = useCallback(() => {
    return (
      <div className='flex gap-2'>
        <span>{formatUSD((totalSupply * price) / BILLION)} B</span>
        <div className='flex items-center rounded-lg bg-primary px-2 text-sm text-white'>
          Rank #{rank}
        </div>
      </div>
    );
  }, [totalSupply, price, rank]);

  return (
    <InfoCard
      icon={MarketDataIcon}
      title='Market data'
      params={{
        Price: `${formatUSD(price)} / ${variables.coinSymbol}`,
        '1h %': <TendPercent value={hourChange} />,
        '24h %': <TendPercent value={dayChange} />,
        '7d %': <TendPercent value={weekChange} />,
        Volume: formatBigUSD(volume),
        MCAP: renderMarketCap(),
      }}
    />
  );
};

export { MarketDataInfoCard };
