import React from 'react';
import clsx from 'clsx';

import { Skeleton } from './skeleton';

const TwoLineParam = ({
  title,
  value,
  className = '',
  valueClassName = '',
  truncate = true,
}) => {
  return (
    <div className={clsx(className, 'flex flex-col gap-1.5')}>
      <span className='font-semibold'>{title}</span>
      {value && (
        <div className={clsx({ truncate: truncate }, valueClassName)}>
          {value}
        </div>
      )}
      {!value && <Skeleton width={120} />}
    </div>
  );
};

export { TwoLineParam };
