import React from 'react';
import clsx from 'clsx';

import { TableHeader } from '.';

const Table = ({ titles, rows, renderRow, renderSkeleton, cols = 3 }) => {
  const gridColsTemplate = {
    3: 'md:grid-cols-[repeat(3,auto)]',
    '3-skeleton': 'md:grid-cols-[repeat(2,auto)_1fr]',
    4: 'md:grid-cols-[repeat(4,auto)]',
    '4-skeleton': 'md:grid-cols-[repeat(3,auto)_1fr]',
  };

  const template = rows.length ? cols : `${cols}-skeleton`;

  return (
    <div className={clsx('grid text-sm', gridColsTemplate[template])}>
      <TableHeader titles={titles} />
      {rows.length ? rows.map((row) => renderRow(row)) : renderSkeleton()}
    </div>
  );
};

export { Table };
