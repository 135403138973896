import {
  getAddressKeyFromOutputs,
  getAmountFromOutputs,
} from './transaction-helpers';
import variables from '../configs/variables';

const getAddressTransactionAmount = (fullTxs, inputs, outputs, address) => {
  let amount = 0;
  for (const input of inputs || []) {
    const inputHash = input.previous_outpoint_hash;
    const inputIndex = +input.previous_outpoint_index;
    const fullOutputs = fullTxs[inputHash]?.outputs || [];

    const publicKey = getAddressKeyFromOutputs(fullOutputs, inputIndex);
    if (publicKey === address) {
      amount -= getAmountFromOutputs(fullOutputs, inputIndex);
    }
  }

  for (const output of outputs || []) {
    const outputPublicKey = output.script_public_key_address;
    if (outputPublicKey === address) {
      amount += output.amount / variables.coinParts;
    }
  }

  return amount;
};

export { getAddressTransactionAmount };
