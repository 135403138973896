import clsx from 'clsx';
import React from 'react';

import { useMobileView } from '../../hooks';

const TableCell = ({
  title,
  value,
  className = '',
  truncate,
  last,
  skeleton,
}) => {
  const isMobile = useMobileView();
  const truncateClassName = { truncate: truncate };
  const skeletonClassName = { skeleton: skeleton };

  return (
    <div
      className={clsx(
        { 'max-md:border-none': !last },
        'group/wrapper border-b border-b-lightBlue',
        className,
        truncateClassName,
      )}>
      <div
        className={clsx(
          'base-cell group-first/wrapper:left-cell group-last/wrapper:right-cell text-nowrap',
          truncateClassName,
          skeletonClassName,
        )}>
        {isMobile && (
          <div className='flex flex-col gap-1'>
            <span className='font-semibold text-primary'>{title}</span>
            <div className={clsx(truncateClassName)}>{value}</div>
          </div>
        )}
        {!isMobile && value}
      </div>
    </div>
  );
};

export { TableCell };
