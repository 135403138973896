import React from 'react';
import { Link } from 'react-router-dom';

import { ValueWithCopy } from '..';

const BlockTransactionId = ({ id }) => {
  return (
    <ValueWithCopy value={id}>
      <Link className='link' to={`/txs/${id}`}>
        {id}
      </Link>
    </ValueWithCopy>
  );
};

export { BlockTransactionId };
