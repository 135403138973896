import React, { useCallback } from 'react';
import { useParams } from 'react-router';

import {
  AddressPublicKey,
  AddressTransaction,
  BackButton,
  Card,
  CoinAmount,
  Paging,
  SectionHeading,
  Spinner,
  TwoLineParam,
} from '../components';
import { InputIcon, TransactionHistoryIcon } from '../icons';
import { useAddressInfo, useAddressTxs, usePaging } from '../hooks';
import {
  formatBigNumber,
  formatUSD,
  getAddressTransactionAmount,
} from '../helpers';
import variables from '../configs/variables';
import useBlueScore from '../contexts/bluescore-context';

const AddressDetails = () => {
  const { address } = useParams();
  const { balance, value, txCount, price, error } = useAddressInfo(address);
  const blueScore = useBlueScore();
  const { limit, page, total, offset, onNext, onPrev } = usePaging(
    txCount,
    variables.addressTxsLimit,
  );
  const { txs, fullTxs, loading } = useAddressTxs(address, offset, limit);

  const renderBalance = useCallback(() => {
    if (balance === null) {
      return null;
    }

    return (
      <CoinAmount
        amount={balance / variables.coinParts}
        negative={balance < 0}
      />
    );
  }, [balance]);

  const renderValue = useCallback(() => {
    if (value === null) {
      return null;
    }

    return formatUSD(value);
  }, [value]);

  const renderTxCount = useCallback(() => {
    if (txCount === null) {
      return null;
    }

    return formatBigNumber(txCount);
  }, [txCount]);

  if (error) {
    return (
      <>
        <BackButton />
        <Card>
          <AddressDetails.Content>
            <SectionHeading icon={InputIcon} title='Address info' />
            <span className='text-neutral-7'>Address not found</span>
          </AddressDetails.Content>
        </Card>
      </>
    );
  }

  return (
    <>
      <BackButton />
      <Card>
        <AddressDetails.Content>
          <SectionHeading icon={InputIcon} title='Address info' />
          <div className='grid grid-cols-4 gap-8 max-lg:grid-cols-2 max-md:grid-cols-1'>
            <TwoLineParam
              title='Address'
              value={<AddressPublicKey publicKey={address} />}
              className='col-span-4 max-lg:col-span-2 max-md:col-span-1'
            />
            <TwoLineParam title='Balance' value={renderBalance()} />
            <TwoLineParam title='Value' value={renderValue()} />
            <TwoLineParam title='Transaction Count' value={renderTxCount()} />
          </div>
        </AddressDetails.Content>
      </Card>

      <Card>
        <AddressDetails.Content>
          <SectionHeading
            icon={TransactionHistoryIcon}
            title='Transaction history'
            bold={false}
          />

          {loading && <Spinner center />}

          {!loading && (
            <div>
              {txs.map((tx) => {
                const amount = getAddressTransactionAmount(
                  fullTxs,
                  tx.inputs,
                  tx.outputs,
                  address,
                );

                return (
                  <AddressTransaction
                    key={tx.transaction_id}
                    timestamp={tx.block_time}
                    transactionId={tx.transaction_id}
                    amount={amount}
                    price={price}
                    fullTxs={fullTxs}
                    inputs={tx.inputs}
                    outputs={tx.outputs}
                    isAccepted={tx.is_accepted}
                    blueScore={blueScore}
                    acceptingBlueScore={tx.accepting_block_blue_score}
                  />
                );
              })}
            </div>
          )}
          <Paging page={page} total={total} onNext={onNext} onPrev={onPrev} />
        </AddressDetails.Content>
      </Card>
    </>
  );
};

AddressDetails.Content = ({ children }) => {
  return <div className='flex flex-col px-4 py-6'>{children}</div>;
};

export { AddressDetails };
