import React, { useMemo } from 'react';

import useBlocks from '../contexts/blocks-context';
import { getTransactionsFromBlocks } from '../helpers';
import { LatestList } from '../components';

const Txs = () => {
  const { blocks } = useBlocks();
  const transactions = useMemo(
    () => getTransactionsFromBlocks(blocks),
    [blocks],
  );

  return (
    <div>
      <div className='mb-4 text-xl font-medium'>Transactions</div>
      <LatestList
        url='/txs'
        name='transactions'
        rowTitles={['ID', 'Amount', 'To']}
        data={transactions}
        lines={20}
      />
    </div>
  );
};

export { Txs };
