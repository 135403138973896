import React from 'react';
import { SectionIcon } from './SectionIcon';
import clsx from 'clsx';

const SectionHeading = ({ icon, title, adornment, bold = true }) => {
  return (
    <div className='mb-6 flex items-center gap-2 text-lg'>
      <SectionIcon icon={icon} />
      <span className={clsx({ 'font-semibold': bold })}>{title}</span>
      {adornment}
    </div>
  );
};

export { SectionHeading };
