import React from 'react';
import clsx from 'clsx';

const SearchIcon = ({ color = '' }) => {
  return (
    <svg
      width='28'
      height='28'
      viewBox='0 0 28 28'
      className={clsx('fill-current', color)}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.83191 4.22029C8.38857 3.18016 10.2187 2.625 12.0909 2.625H12.0909C14.6014 2.62516 17.0089 3.6225 18.7841 5.39765C20.5592 7.17279 21.5566 9.58037 21.5567 12.0908V12.0909C21.5567 13.963 21.0016 15.7932 19.9614 17.3498C18.9213 18.9065 17.443 20.1197 15.7133 20.8362C13.9836 21.5526 12.0804 21.7401 10.2442 21.3748C8.40797 21.0096 6.72131 20.1081 5.39749 18.7842C4.07367 17.4604 3.17213 15.7738 2.80689 13.9376C2.44165 12.1014 2.6291 10.1981 3.34555 8.46843C4.062 6.73877 5.27526 5.26041 6.83191 4.22029ZM12.0908 4.375C10.5648 4.37501 9.07301 4.82754 7.80416 5.67536C6.5353 6.52319 5.54633 7.72824 4.96234 9.13813C4.37834 10.548 4.22554 12.0994 4.52326 13.5961C4.82098 15.0929 5.55584 16.4677 6.63493 17.5468C7.71401 18.6259 9.08884 19.3607 10.5856 19.6585C12.0823 19.9562 13.6337 19.8034 15.0436 19.2194C16.4535 18.6354 17.6585 17.6464 18.5064 16.3776C19.3542 15.1087 19.8067 13.617 19.8067 12.0909M12.0909 4.375C14.1372 4.37515 16.0997 5.18811 17.5466 6.63508C18.9936 8.08206 19.8066 10.0445 19.8067 12.0909'
        fill='#203293'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.8815 17.8815C18.2232 17.5398 18.7773 17.5398 19.119 17.8815L25.1187 23.8813C25.4604 24.223 25.4604 24.777 25.1187 25.1187C24.777 25.4604 24.223 25.4604 23.8813 25.1187L17.8815 19.119C17.5398 18.7773 17.5398 18.2232 17.8815 17.8815Z'
        fill='#203293'
      />
    </svg>
  );
};

export { SearchIcon };
