import clsx from 'clsx';
import React from 'react';

const TableHeader = ({ titles }) => {
  return (
    <div className='contents text-primary max-md:hidden'>
      {titles.map((title) => {
        const classNames = {
          'text-right': title === 'Amount',
        };
        return (
          <div key={title} className={clsx('group', classNames)}>
            <div className='base-cell group-last:right-cell group-first:left-cell font-semibold'>
              {title}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export { TableHeader };
