import React from 'react';
import { Link } from 'react-router-dom';

import { getOutputByIndex } from '../../helpers';
import variables from '../../configs/variables';
import { CoinAmount, TwoLineParam } from '..';

const TransactionInput = ({
  info,
  signatureOpCount,
  signatureScript,
  previousOutpointHash,
  previousOutpointIndex,
}) => {
  const output = getOutputByIndex(
    info[previousOutpointHash]?.outputs,
    previousOutpointIndex,
  );
  const amount = (output.amount || 0) / variables.coinParts;
  const publicKey = output?.script_public_key_address || 'unknown';

  return (
    <div className='border-b-4 border-lightBlue py-3 first:pt-0 last:border-none last:pb-0'>
      <div className='grid grid-cols-[auto_auto_minmax(auto,50%)] gap-8 max-md:grid-cols-1'>
        <TwoLineParam
          title='Amount'
          value={
            <CoinAmount
              amount={-amount}
              bg='bg-status-fail/10'
              color='text-status-fail'
            />
          }
        />
        <TwoLineParam title='Signature Op Count' value={signatureOpCount} />
        <TwoLineParam
          title='Signature Script'
          value={signatureScript}
          valueClassName='break-words'
          truncate={false}
        />
        <TwoLineParam
          title='Previous Outpoint Index + Hash'
          value={`#${previousOutpointIndex} ${previousOutpointHash}`}
          className='md:col-span-3'
          valueClassName='whitespace-nowrap'
        />
        <TwoLineParam
          title='Address'
          value={
            <Link className='link' to={`/addresses/${publicKey}`}>
              {publicKey}
            </Link>
          }
          className='md:col-span-3'
          valueClassName='whitespace-nowrap'
        />
      </div>
    </div>
  );
};

export { TransactionInput };
