const getTransactionsIds = (transactions) => {
  if (!transactions) {
    return [];
  }

  return transactions
    .flatMap((tx) =>
      tx.inputs?.flatMap((txInput) => txInput.previousOutpoint.transactionId),
    )
    .filter(Boolean)
    .concat(transactions.map((tx) => tx.verboseData.transactionId));
};

export { getTransactionsIds };
