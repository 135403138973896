import React from 'react';
import clsx from 'clsx';

const CoinSupplyIcon = ({ color, className }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      className={clsx('fill-current', color, className)}>
      <g clipPath='url(#clip0_178_99)'>
        <path d='M10.8782 14.1946V15.4895C11.3993 15.5466 11.9231 15.5743 12.4472 15.5725V14.2807C11.9132 14.2776 11.4002 14.2438 10.8782 14.1946Z' />
        <path d='M12.4471 11.7617V10.4575C10.6471 10.4575 8.82306 10.15 7.91406 9.57788V10.6236C7.91706 11.0204 9.51606 11.7524 12.4471 11.7617Z' />
        <path d='M12.492 7.95382C15.453 7.95382 17.067 7.21257 17.067 6.83425V5.78235C16.152 6.36366 14.316 6.66509 12.492 6.66509C10.668 6.66509 8.83199 6.35751 7.91699 5.78235V6.83425C7.91699 7.21257 9.53099 7.95382 12.492 7.95382Z' />
        <path d='M9.86405 22.2438C9.15176 22.5198 8.40572 22.6937 7.64705 22.7605C7.11297 22.8191 6.57618 22.8479 6.03905 22.8467H5.79005C4.26005 22.8251 2.64605 22.5668 1.80005 22.05V22.9728C1.80005 23.2988 3.21305 23.9508 5.80505 23.9878C5.89205 23.9878 5.96405 23.9878 6.03905 23.9878C7.07804 24.0042 8.11471 23.8822 9.12305 23.6248C10.071 23.3542 10.278 23.0589 10.278 22.9666V22.0439C10.1528 22.119 10.0225 22.1848 9.88805 22.2407L9.86405 22.2438Z' />
        <path d='M6.03905 15.1265C8.78105 15.1265 10.278 14.4529 10.278 14.1054C10.2759 14.0757 10.2643 14.0476 10.245 14.0254C10.038 13.7178 9.13805 13.3334 7.58405 13.1642C7.07072 13.1104 6.55504 13.0838 6.03905 13.0842C3.30005 13.0812 1.80005 13.7578 1.80005 14.1054C1.80005 14.4529 3.30005 15.1265 6.03905 15.1265Z' />
        <path d='M1.80005 20.2661V21.1888C1.80005 21.5148 3.21305 22.1669 5.80505 22.2038H6.03905C6.5547 22.213 7.07046 22.1935 7.58405 22.1453C8.2927 22.0878 8.99025 21.9304 9.65705 21.6778H9.68105C10.152 21.4779 10.281 21.2934 10.281 21.2165V20.2937C9.39905 20.8351 7.67705 21.0873 6.04205 21.0873C4.40705 21.0873 2.68205 20.8074 1.80005 20.2661Z' />
        <path d='M12.492 6.05001C15.453 6.05001 17.067 5.30876 17.067 4.93044V3.87854C16.152 4.45986 14.316 4.76128 12.492 4.76128C10.668 4.76128 8.83199 4.4537 7.91699 3.87854V4.92122C7.91699 5.30876 9.53099 6.05001 12.492 6.05001Z' />
        <path d='M22.2 10.3929C21.285 10.9743 19.449 11.2757 17.625 11.2757C15.801 11.2757 13.965 10.9681 13.05 10.3929V11.4448C13.05 11.8262 14.664 12.5644 17.625 12.5644C20.586 12.5644 22.2 11.8232 22.2 11.4448V10.3929Z' />
        <path d='M13.05 9.53485C13.05 9.91625 14.664 10.6544 17.625 10.6544C20.586 10.6544 22.2 9.91932 22.2 9.53485C22.2 9.15039 20.586 8.41528 17.625 8.41528H17.358C14.568 8.46757 13.05 9.17192 13.05 9.53485Z' />
        <path d='M12.492 4.14608C15.453 4.14608 17.067 3.40483 17.067 3.02651V1.97461C16.152 2.55592 14.316 2.85735 12.492 2.85735C10.668 2.85735 8.83199 2.54977 7.91699 1.97461V3.02651C7.91699 3.40483 9.53099 4.14608 12.492 4.14608Z' />
        <path d='M12.492 2.24222C15.453 2.24222 17.067 1.50096 17.067 1.12265C17.067 0.74433 15.453 0 12.492 0C9.53099 0 7.91699 0.741254 7.91699 1.11957C7.91699 1.49789 9.53099 2.24222 12.492 2.24222Z' />
        <path d='M13.047 12.303V13.3487C13.047 13.7301 14.661 14.4683 17.622 14.4683C20.583 14.4683 22.2 13.7301 22.2 13.3487V12.303C21.285 12.8843 19.449 13.1857 17.625 13.1857C15.801 13.1857 13.962 12.8781 13.047 12.303Z' />
        <path d='M17.349 20.7889C15.69 20.7643 13.941 20.4813 13.05 19.9185V20.9642C13.05 21.1334 13.455 21.4871 14.571 21.7609H14.595C15.5907 21.979 16.607 22.0822 17.625 22.0684C20.586 22.0684 22.2 21.3302 22.2 20.9489V19.9154C21.285 20.4967 19.449 20.7981 17.625 20.7981C17.532 20.7951 17.436 20.7951 17.349 20.7889Z' />
        <path d='M17.625 16.3722C20.586 16.3722 22.2 15.6309 22.2 15.2526V14.2007C21.285 14.782 19.449 15.0712 17.625 15.0712C15.801 15.0712 13.962 14.7636 13.047 14.1884V15.2403C13.05 15.634 14.664 16.3722 17.625 16.3722Z' />
        <path d='M12.492 8.56902C10.668 8.56902 8.83199 8.26144 7.91699 7.68628V8.73818C7.91699 9.1165 9.51599 9.84853 12.45 9.85468V9.5348C12.45 9.13803 12.771 8.80585 13.296 8.54441C13.029 8.56287 12.759 8.56902 12.492 8.56902Z' />
        <path d='M14.475 22.3699H14.451C13.9599 22.2683 13.4872 22.0888 13.05 21.8378V22.8805C13.05 23.2619 14.664 24 17.625 24C20.586 24 22.2 23.2496 22.2 22.8712V21.8193C21.285 22.4007 19.449 22.7021 17.625 22.7021C16.5663 22.7114 15.5098 22.6 14.475 22.3699Z' />
        <path d='M17.349 18.8851C15.69 18.8605 13.941 18.5775 13.05 18.0146V19.0696C13.05 19.4356 14.535 20.143 17.3731 20.183C17.463 20.183 17.544 20.183 17.625 20.183C20.586 20.18 22.2 19.4418 22.2 19.0696V18.0085C21.285 18.5898 19.449 18.8912 17.625 18.8912C17.532 18.8912 17.436 18.8912 17.349 18.8851Z' />
        <path d='M22.2 17.1565V16.1046C21.285 16.6859 19.449 16.9873 17.625 16.9873C15.801 16.9873 13.965 16.6797 13.05 16.1046V17.1565C13.05 17.5225 14.535 18.2299 17.3731 18.2699C17.463 18.2699 17.544 18.2699 17.625 18.2699C20.586 18.276 22.2 17.5317 22.2 17.1565Z' />
        <path d='M1.80005 18.4944V19.4172C1.80005 19.7647 3.30005 20.4383 6.03905 20.4383C8.13905 20.4383 9.50105 20.0446 10.029 19.7032C10.0558 19.6759 10.0874 19.654 10.122 19.6386C10.224 19.5587 10.278 19.4818 10.278 19.4172V18.4944C9.39605 19.0358 7.67405 19.288 6.03905 19.288C4.40405 19.288 2.68205 19.0327 1.80005 18.4944Z' />
        <path d='M12.4501 16.1876C11.9011 16.1876 11.3761 16.16 10.8811 16.1077V17.5932C11.3881 17.6455 11.9071 17.6701 12.4501 17.6763V17.1565V16.1876Z' />
        <path d='M1.80005 16.7197V17.6424C1.80005 17.99 3.30005 18.6636 6.03905 18.6636C8.77805 18.6636 10.278 17.9869 10.278 17.6424V16.7197C9.39605 17.2611 7.67405 17.5133 6.03905 17.5133C4.40405 17.5133 2.68205 17.2611 1.80005 16.7197Z' />
        <path d='M1.80005 14.9481V15.8708C1.80005 16.2184 3.30005 16.892 6.03905 16.892C8.77805 16.892 10.278 16.2153 10.278 15.8708V14.9481C9.39605 15.4895 7.67405 15.7417 6.03905 15.7417C4.40405 15.7417 2.68205 15.4895 1.80005 14.9481Z' />
        <path d='M10.8782 19.4233V19.6848C11.3762 19.7371 11.8952 19.7647 12.4472 19.7709V18.2915C11.9012 18.2915 11.3762 18.2638 10.8782 18.2115V19.4233Z' />
        <path d='M10.8782 21.198V21.8132C11.4001 21.8723 11.925 21.9 12.4502 21.8962V20.9735V20.3952C11.9072 20.3952 11.3822 20.3614 10.8812 20.3122L10.8782 21.198Z' />
        <path d='M12.447 12.3768C10.647 12.3768 8.82599 12.0693 7.91699 11.5002V12.5829H7.94399H8.01899H8.12399H8.20499L8.32499 12.6014H8.39999L8.56199 12.6321H8.60399L8.81099 12.6752H8.87399L9.02399 12.709L9.10499 12.7305L9.23999 12.7674L9.32399 12.7921L9.45899 12.832L9.53999 12.8566L9.68699 12.9089L9.74699 12.9305L9.94199 13.0104L9.98099 13.0289L10.125 13.0996L10.2 13.2257L10.311 13.2903L10.371 13.3303L10.467 13.4011L10.518 13.441L10.608 13.5241L10.647 13.561C11.2445 13.6322 11.8455 13.6681 12.447 13.6686V12.3768Z' />
      </g>
      <defs>
        <clipPath id='clip0_178_99'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export { CoinSupplyIcon };
