import React from 'react';
import { Link } from 'react-router-dom';

import variables from '../../configs/variables';
import {
  formatCoinsAmount,
  getAddressKeyFromOutputs,
  getAmountFromOutputs,
} from '../../helpers';

const AddressTransactionFrom = ({ fullTxs, inputs }) => {
  if (!inputs?.length) {
    return <span>{variables.coinBaseText}</span>;
  }

  return (
    <ul>
      {inputs.map((input) => {
        const inputHash = input.previous_outpoint_hash;
        const inputIndex = input.previous_outpoint_index;

        const key = `${inputHash}${inputIndex}`;
        const publicKey = getAddressKeyFromOutputs(
          fullTxs[inputHash]?.outputs,
          +inputIndex,
        );
        const amount = getAmountFromOutputs(
          fullTxs[inputHash]?.outputs,
          +inputIndex,
        );

        return (
          <li key={key} className='flex items-center overflow-hidden'>
            <Link className='link truncate' to={`/addresses/${publicKey}`}>
              {publicKey}
            </Link>
            <span className='ml-2 text-status-fail'>
              {formatCoinsAmount(-amount, true)}
              &nbsp;{variables.coinSymbol}
            </span>
          </li>
        );
      })}
    </ul>
  );
};

export { AddressTransactionFrom };
