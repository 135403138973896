import { useEffect, useMemo, useState } from 'react';

import usePrice from '../contexts/price-context';
import variables from '../configs/variables';
import ApiClient from '../services/api-client';

const useAddressInfo = (publicKey) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { price } = usePrice();
  const [balance, setBalance] = useState(null);
  const [txCount, setTxCount] = useState(null);

  const value = useMemo(() => {
    if (balance === null) {
      return null;
    }
    return (balance / variables.coinParts) * price;
  }, [balance, price]);

  useEffect(() => {
    fetchAddressInfo();
  }, [publicKey]);

  const fetchAddressInfo = async () => {
    try {
      setLoading(true);

      const _balance = await ApiClient.getAddressBalance(publicKey);
      setBalance(_balance);

      const _txCount = await ApiClient.getAddressTxCount(publicKey);
      setTxCount(_txCount);

      setError(null);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  return { balance, txCount, value, loading, error, price };
};

export { useAddressInfo };
