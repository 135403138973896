import { useEffect, useState } from 'react';

import { convertTxsArrayToMap, getTransactionInputsIds } from '../helpers';
import ApiClient from '../services/api-client';

const useTransactionAdditionalInfo = (tx) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [info, setInfo] = useState({});

  const inputs = tx?.inputs;
  const inputsIds = getTransactionInputsIds(inputs);

  useEffect(() => {
    if (!inputsIds.length) {
      return;
    }
    fetchInputsAddresses();
  }, [inputsIds.length]);

  const fetchInputsAddresses = async () => {
    try {
      setLoading(true);

      const txs = await ApiClient.getTransactionList(inputsIds);
      setInfo(convertTxsArrayToMap(txs));

      setError(null);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  return { info, loading, error };
};

export { useTransactionAdditionalInfo };
