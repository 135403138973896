import React from 'react';
import clsx from 'clsx';

const TransactionHistoryIcon = ({ color }) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      className={clsx('fill-current', color)}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.5 4.5C4.5 4.22386 4.72386 4 5 4H14C14.2761 4 14.5 4.22386 14.5 4.5C14.5 4.77614 14.2761 5 14 5H5C4.72386 5 4.5 4.77614 4.5 4.5ZM4.5 8C4.5 7.72386 4.72386 7.5 5 7.5H14C14.2761 7.5 14.5 7.72386 14.5 8C14.5 8.27614 14.2761 8.5 14 8.5H5C4.72386 8.5 4.5 8.27614 4.5 8ZM4.5 11.5C4.5 11.2239 4.72386 11 5 11H14C14.2761 11 14.5 11.2239 14.5 11.5C14.5 11.7761 14.2761 12 14 12H5C4.72386 12 4.5 11.7761 4.5 11.5Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.5 4.5C1.5 3.94772 1.94772 3.5 2.5 3.5C3.05228 3.5 3.5 3.94772 3.5 4.5C3.5 5.05228 3.05228 5.5 2.5 5.5C1.94772 5.5 1.5 5.05228 1.5 4.5Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.5 8C1.5 7.44772 1.94772 7 2.5 7C3.05228 7 3.5 7.44772 3.5 8C3.5 8.55228 3.05228 9 2.5 9C1.94772 9 1.5 8.55228 1.5 8Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.5 11.5C1.5 10.9477 1.94772 10.5 2.5 10.5C3.05228 10.5 3.5 10.9477 3.5 11.5C3.5 12.0523 3.05228 12.5 2.5 12.5C1.94772 12.5 1.5 12.0523 1.5 11.5Z'
      />
    </svg>
  );
};

export { TransactionHistoryIcon };
