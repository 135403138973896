import { useState } from 'react';
import { useNavigate } from 'react-router';

import variables from '../configs/variables';
import ApiClient from '../services/api-client';

const entityChecker = {
  block: ApiClient.getBlockDetails,
  tx: ApiClient.getTransactionDetails,
  address: ApiClient.getAddressBalance,
};

const useSearch = (clear) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const onSearch = async (term) => {
    try {
      setLoading(true);

      if (isHash(term)) {
        const blockExists = await isExists(term, 'block');
        if (blockExists) {
          navigate(`/blocks/${term}`);
          return;
        }

        const txExists = await isExists(term, 'tx');
        if (txExists) {
          navigate(`/txs/${term}`);
          return;
        }

        goToNothingFound();
      }

      if (isAddress(term)) {
        const addressExists = await isExists(term, 'address');
        addressExists ? navigate(`/addresses/${term}`) : goToNothingFound();
        return;
      }

      goToNothingFound();

      setError(null);
    } catch (e) {
      setError(e);
    } finally {
      clear?.();
      setLoading(false);
    }
  };

  const isAddress = (value) => {
    return value.startsWith(`${variables.addressPrefix}:`);
  };

  const isHash = (value) => {
    return value.length === 64;
  };

  const isExists = async (term, type) => {
    try {
      await entityChecker[type](term);
      return true;
    } catch (e) {
      return false;
    }
  };

  const goToNothingFound = () => {
    navigate('/search');
  };

  return { onSearch, loading, error };
};

export { useSearch };
