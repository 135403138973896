import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

const BlockMinerInfo = ({ minerName, minerAddress }) => {
  const processedMinerName = minerName || 'No miner name';
  const processedMinerAddress = minerAddress || 'No miner address';

  const Address = minerAddress ? Link : 'span';

  return (
    <div className='flex flex-col'>
      <span className='truncate'>{processedMinerName}</span>
      <Address
        className={clsx({ link: !!minerAddress }, 'truncate')}
        to={`/addresses/${minerAddress}`}>
        {processedMinerAddress}
      </Address>
    </div>
  );
};

export { BlockMinerInfo };
