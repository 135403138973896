import React, { useCallback } from 'react';
import { useParams } from 'react-router';

import {
  BackButton,
  BlockBlueScore,
  BlockChildren,
  BlockMinerInfo,
  BlockParents,
  BlockTransaction,
  Card,
  ItemParam,
  SectionHeading,
  Spinner,
} from '../components';
import useBlueScore from '../contexts/bluescore-context';
import usePrice from '../contexts/price-context';
import {
  getBlockBlueWork,
  getBlockChildrenHashes,
  getBlockInChainStatus,
  getBlockMinerInfo,
  getBlockParentsHashes,
  getBlockTimestamp,
  getTransactionAmount,
} from '../helpers';
import { useBlockDetails, useBlockTxs } from '../hooks';
import { BlockIcon, TransactionIcon } from '../icons';

const BlockDetails = () => {
  const { hash } = useParams();

  const blueScore = useBlueScore();
  const { price } = usePrice();

  const { block } = useBlockDetails(hash);
  const { transactions: allTransactions, loading } = useBlockTxs(block);
  const blockTransactions = block?.transactions || [];

  const { minerName, minerAddress } = getBlockMinerInfo(block);
  const parents = getBlockParentsHashes(block);
  const children = getBlockChildrenHashes(block);
  const timestamp = getBlockTimestamp(block);
  const isChain = getBlockInChainStatus(block);
  const blueWork = getBlockBlueWork(block);

  const isNotReadyToShowTxs =
    loading ||
    !blockTransactions.length ||
    Object.keys(allTransactions).length === 0;

  const renderMinerInfo = useCallback(() => {
    return block ? (
      <BlockMinerInfo minerName={minerName} minerAddress={minerAddress} />
    ) : null;
  }, [minerName, minerAddress]);

  return (
    <>
      <BackButton />
      <Card>
        <BlockDetails.Content>
          <SectionHeading
            icon={BlockIcon}
            title='Block Details'
            adornment={<BlockBlueScore blueScore={block?.header.blueScore} />}
          />
          <ItemParam title='Hash' value={block?.verboseData.hash} hasCopy />
          <ItemParam title='Block Index' value={block?.header.blueScore} />
          <ItemParam title='Bits' value={block?.header.bits} />
          <ItemParam title='Timestamp' value={timestamp} />
          <ItemParam title='Version' value={block?.header.version} />
          <ItemParam title='Is Chain Block' value={isChain} />
          <ItemParam
            title='Parents'
            value={<BlockParents parents={parents} />}
          />
          <ItemParam
            title='Children'
            // eslint-disable-next-line react/no-children-prop
            value={<BlockChildren children={children} />}
          />
          <ItemParam
            title='Hash Tree Root'
            value={block?.header.hashMerkleRoot}
          />
          <ItemParam
            title='Accepted Hash Tree Root'
            value={block?.header.acceptedIdMerkleRoot}
          />
          <ItemParam
            title='UTXO Commitment'
            value={block?.header.utxoCommitment}
          />
          <ItemParam title='Nonce' value={block?.header.nonce} />
          <ItemParam
            title='Global Block Index'
            value={block?.header.daaScore}
          />
          <ItemParam title='Mining Work' value={blueWork} />
          <ItemParam title='Miner Info' value={renderMinerInfo()} />
        </BlockDetails.Content>
      </Card>

      {isNotReadyToShowTxs && <Spinner center />}

      {!isNotReadyToShowTxs && (
        <Card>
          <BlockDetails.Content>
            <SectionHeading
              icon={TransactionIcon}
              title='Transactions'
              bold={false}
            />
            <div>
              {blockTransactions.map((tx) => {
                const id = tx.verboseData.transactionId;
                const amount = getTransactionAmount(tx);
                const fullTransaction = allTransactions[id];
                const isAccepted = fullTransaction?.is_accepted;
                const acceptingBlueScore =
                  fullTransaction?.accepting_block_blue_score;

                return (
                  <BlockTransaction
                    key={id}
                    id={id}
                    amount={amount}
                    price={price}
                    blueScore={blueScore}
                    isAccepted={isAccepted}
                    acceptingBlueScore={acceptingBlueScore}
                    inputs={tx.inputs}
                    outputs={tx.outputs}
                    allTransactions={allTransactions}
                  />
                );
              })}
            </div>
          </BlockDetails.Content>
        </Card>
      )}
    </>
  );
};

BlockDetails.Content = ({ children }) => {
  return <div className='flex flex-col px-4 py-6'>{children}</div>;
};

export { BlockDetails };
