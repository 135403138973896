import React from 'react';
import { Link } from 'react-router-dom';

import { AddressTransactionTime } from '.';
import {
  AddressTransactionDetails,
  AddressTransactionFrom,
  AddressTransactionTo,
  CoinAmount,
  TwoLineParam,
} from '..';
import { formatUSD } from '../../helpers';

const AddressTransaction = ({
  timestamp,
  transactionId,
  amount,
  price,
  fullTxs,
  inputs,
  outputs,
  isAccepted,
  blueScore,
  acceptingBlueScore,
}) => {
  return (
    <div className='border-b-4 border-lightBlue py-3 first:pt-0 last:border-none last:pb-0'>
      <div className='grid w-fit grid-cols-2 gap-8'>
        <AddressTransactionTime timestamp={timestamp} />
        <TwoLineParam
          title='Transaction ID'
          className='col-span-2'
          value={
            <Link className='link' to={`/txs/${transactionId}`}>
              {transactionId}
            </Link>
          }
        />
        <TwoLineParam
          title='Amount'
          value={
            <CoinAmount
              amount={amount}
              bg={amount < 0 ? 'bg-status-fail/10' : 'bg-status-success/10'}
              color={amount < 0 ? 'text-status-fail' : 'text-status-success'}
            />
          }
        />
        <TwoLineParam title='Value' value={formatUSD(amount * price)} />
        <TwoLineParam
          title='From'
          className='col-span-2'
          value={<AddressTransactionFrom fullTxs={fullTxs} inputs={inputs} />}
        />
        <TwoLineParam
          title='To'
          className='col-span-2'
          value={<AddressTransactionTo outputs={outputs} />}
        />
        <AddressTransactionDetails
          acceptingBlueScore={acceptingBlueScore}
          isAccepted={isAccepted}
          blueScore={blueScore}
        />
      </div>
    </div>
  );
};

export { AddressTransaction };
