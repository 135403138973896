import React from 'react';

import { DesktopNavigation, MobileNavigation } from '.';
import { MainWrapper } from '../wrappers';
import { useMobileView } from '../../hooks';

const Header = ({ isDynamicLogo }) => {
  const isMobile = useMobileView();
  const Navigation = isMobile ? MobileNavigation : DesktopNavigation;

  return (
    <header className='left-0 top-0 z-10 w-full bg-secondary md:fixed md:shadow-card'>
      <MainWrapper>
        <Navigation isDynamicLogo={isDynamicLogo} />
      </MainWrapper>
    </header>
  );
};

export { Header };
