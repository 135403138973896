import React from 'react';

import { ServerInfoIcon } from '../../icons';
import { InfoCard } from './InfoCard';
import useStats from '../../contexts/stats-context';

const ServerInfoCard = () => {
  const { mempoolSize, serverVersion } = useStats();

  return (
    <InfoCard
      icon={ServerInfoIcon}
      title='Server info'
      params={{
        'Mempool size': mempoolSize,
        'Server version': serverVersion,
      }}
    />
  );
};

export { ServerInfoCard };
