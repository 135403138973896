import { useEffect, useState } from 'react';
import ApiClient from '../services/api-client';

const useBlockDetails = (hash) => {
  const [block, setBlock] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchBlock();
  }, [hash]);

  const fetchBlock = async () => {
    try {
      setLoading(true);
      setBlock(null);
      const details = await ApiClient.getBlockDetails(hash);
      setBlock(details);

      setError(null);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  return { block, loading, error };
};

export { useBlockDetails };
