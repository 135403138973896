import React from 'react';

import { LatestList } from '../components';
import useBlocks from '../contexts/blocks-context';

const Blocks = () => {
  const { blocks } = useBlocks();

  return (
    <div>
      <div className='mb-4 text-xl font-medium'>Blocks</div>
      <LatestList
        url='/blocks'
        name='blocks'
        rowTitles={['Block Index', 'Timestamp', 'TXs', 'Hash']}
        data={blocks}
        lines={20}
      />
    </div>
  );
};

export { Blocks };
