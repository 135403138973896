import React from 'react';
import clsx from 'clsx';

const TendArrowIcon = ({ color, className }) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      className={clsx('fill-current', color, className)}>
      <path d='M12.9376 10.0606L8.56943 4.96312C8.49902 4.881 8.41168 4.81508 8.3134 4.76989C8.21512 4.72469 8.10823 4.70129 8.00005 4.70129C7.89188 4.70129 7.78499 4.72469 7.68671 4.76989C7.58843 4.81508 7.50109 4.881 7.43068 4.96312L3.06255 10.0606C2.64568 10.5472 2.9913 11.2987 3.63193 11.2987H12.3694C13.0101 11.2987 13.3557 10.5472 12.9376 10.0606Z' />
    </svg>
  );
};

export { TendArrowIcon };
