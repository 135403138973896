import React from 'react';

const Search = () => {
  return (
    <div className='flex flex-1 items-center justify-center text-neutral-7'>
      No results found
    </div>
  );
};

export { Search };
