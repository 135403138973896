import React from 'react';
import clsx from 'clsx';

const ConfirmingIcon = ({ color }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      className={clsx('fill-current', color)}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.2304 2.25035C17.5087 2.3729 21.75 6.69233 21.75 12.0001C21.75 17.3897 17.3934 21.7603 11.9986 21.7501C6.5992 21.74 2.19025 17.3028 2.25005 11.8983C2.27445 9.53481 3.15722 7.26075 4.73391 5.49981C5.01021 5.19122 5.48436 5.16505 5.79296 5.44135C6.10155 5.71765 6.12772 6.1918 5.85142 6.5004C4.51742 7.99029 3.77055 9.91434 3.74997 11.9141L3.74996 11.9147C3.6993 16.4804 7.4306 20.2415 12.0014 20.2501C16.5629 20.2587 20.25 16.564 20.25 12.0001C20.25 7.69613 16.9541 4.16023 12.75 3.7813V7.12511C12.75 7.53932 12.4142 7.87511 12 7.87511C11.5858 7.87511 11.25 7.53932 11.25 7.12511V3.20569C11.2498 3.07801 11.2753 2.9516 11.3248 2.83394C11.3746 2.71585 11.4476 2.60898 11.5395 2.51971C11.6314 2.43045 11.7404 2.3606 11.8599 2.31435C11.9779 2.26865 12.1039 2.2469 12.2304 2.25035Z'
      />
      <path d='M10.9397 13.0607L7.23658 7.76378C7.18534 7.69039 7.16158 7.60131 7.16944 7.51216C7.17731 7.423 7.2163 7.33946 7.27959 7.27617C7.34288 7.21288 7.42642 7.17389 7.51558 7.16603C7.60473 7.15816 7.69381 7.18193 7.7672 7.23316L13.0641 10.9363C13.389 11.1703 13.6087 11.5228 13.6757 11.9175C13.7427 12.3123 13.6516 12.7175 13.4221 13.0456C13.1927 13.3737 12.8433 13.5983 12.4495 13.6708C12.0557 13.7432 11.6492 13.6578 11.318 13.4328C11.1715 13.3313 11.0436 13.2055 10.9397 13.0607Z' />
    </svg>
  );
};

export { ConfirmingIcon };
