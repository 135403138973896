import React from 'react';
import clsx from 'clsx';

const BlockIcon = ({ color, className }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      className={clsx('fill-current', color, className)}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.999 7.20141L13.1673 12.7984L13.1647 24C13.2137 23.9746 13.2652 23.9492 13.3142 23.9212L21.6875 19.1554H21.69L21.6926 19.1529C22.0948 18.9292 22.4325 18.6039 22.6645 18.2099C22.8914 17.8109 23.0074 17.3635 22.9996 16.9085V7.37463C22.9996 7.31617 22.9996 7.26025 22.9945 7.20179L22.999 7.20141ZM2.16478 5.21629C2.21376 5.18579 2.26274 5.15529 2.3143 5.12479C2.33492 5.11208 2.35554 5.10192 2.37359 5.08921L10.6901 0.356482C11.4996 -0.118827 12.5101 -0.118827 13.3196 0.356482C13.3402 0.36919 13.3634 0.379358 13.3866 0.392066L21.6952 5.12225C21.7468 5.15275 21.7983 5.18325 21.8473 5.21375L12.0024 10.8183L2.16478 5.21629ZM10.8397 12.7981L1.00791 7.20115C1.00534 7.25707 1.00276 7.31553 1.00276 7.37399L1.00018 16.9053C0.995024 17.3425 1.10071 17.7745 1.30953 18.1609C1.31984 18.1762 1.33015 18.194 1.34046 18.2092H1.33788C1.56215 18.5854 1.87923 18.9006 2.26074 19.1242C2.27878 19.1344 2.29683 19.1446 2.31745 19.1547L10.6881 23.9205C10.7371 23.9485 10.7886 23.9764 10.8376 23.9993L10.8397 12.7981Z'
      />
    </svg>
  );
};

export { BlockIcon };
