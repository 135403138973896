import React, { createContext, useContext, useEffect, useState } from 'react';

import ApiClient from '../services/api-client';
import variables from '../configs/variables';

const defaultState = {
  price: 0,
  hourChange: 0,
  dayChange: 0,
  weekChange: 0,
  volume: 0,
  rank: 0,
};

const PriceContext = createContext(defaultState);

const PriceProvider = ({ children }) => {
  const [value, setValue] = useState(defaultState);

  const getPrice = () => {
    ApiClient.getPrice()
      .then(({ price, dayChange, hourChange, rank, volume, weekChange }) =>
        setValue({
          price,
          dayChange,
          hourChange,
          weekChange,
          volume,
          rank,
        }),
      )
      .catch((e) => console.warn('ERROR fetching price', e.response.data));
  };

  useEffect(() => {
    if (variables.isTestnet) {
      return;
    }

    getPrice();
    const interval = setInterval(getPrice, variables.statsCheckInterval * 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <PriceContext.Provider value={value}>{children}</PriceContext.Provider>
  );
};

const usePrice = () => {
  return useContext(PriceContext);
};

export { PriceProvider };
export default usePrice;
