import React from 'react';
import { Link } from 'react-router-dom';

import { DiscordIcon, GitHubIcon, MediumIcon, TwitterIcon } from '../../icons';

const socials = [
  { Icon: TwitterIcon, url: 'https://x.com/' },
  { Icon: DiscordIcon, url: 'https://discord.com/' },
  { Icon: GitHubIcon, url: 'https://github.com/' },
  { Icon: MediumIcon, url: 'https://medium.com/' },
];

const Socials = () => {
  return (
    <div className='flex gap-4'>
      {socials.map(({ Icon, url }) => (
        <Link
          to={url}
          target='_blank'
          key={url}
          className='flex h-[24px] w-[24px] items-center justify-center max-md:h-[20px] max-md:w-[20px]'>
          <Icon color='text-white hover:text-white/80 transition-colors active:text-white/80' />
        </Link>
      ))}
    </div>
  );
};

export { Socials };
