import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { BlockIcon } from '../../icons';
import clsx from 'clsx';

const Logo = ({ isDynamic = false, white = false }) => {
  const source = useMemo(() => {
    if (isDynamic) {
      return '/no_cube_logo.svg';
    }
    if (white) {
      return '/logo_white.svg';
    }
    return '/logo.svg';
  }, [isDynamic, white]);

  return (
    <Link
      to='/'
      className={clsx({ 'md:-mt-[10px]': !white }, 'relative shrink-0')}>
      {isDynamic && (
        <BlockIcon
          color='text-[#0360DD]'
          className='logo-cube-shadow absolute -left-[0.5px] -top-[0px] h-[15.5px] w-[15.5px] animate-cube max-md:-left-[0.5px] max-md:-top-[0px] max-md:h-[12px] max-md:w-[12px]'
        />
      )}
      <img src={source} alt='logo' className='h-[36px] max-md:h-[28px]' />
    </Link>
  );
};

export { Logo };
