import React from 'react';

const MainWrapper = ({ children }) => {
  return (
    <div className='mx-auto flex h-full w-full max-w-content flex-1 flex-col px-10 max-lg:px-4'>
      {children}
    </div>
  );
};

export { MainWrapper };
