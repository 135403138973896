import React from 'react';

const NotFound = () => {
  return (
    <div className='flex flex-1 flex-col items-center justify-center gap-4'>
      <span className='text-7xl font-semibold'>404</span>
      <span className='text-2xl'>Page not found</span>
    </div>
  );
};

export { NotFound };
