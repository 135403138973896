import React, { memo, useState } from 'react';
import copy from 'copy-to-clipboard';

import { CheckIcon, CopyIcon } from '../../icons';
import { Tooltip } from '..';

const CopyButton = memo(({ value }) => {
  const [succeeded, setSucceeded] = useState(false);

  const handleCopy = async () => {
    if (!value) {
      return;
    }
    copy(value);
    setSucceeded(true);
    setTimeout(() => setSucceeded(false), 2000);
  };

  return (
    <>
      {!succeeded && (
        <Tooltip content='Copy to clipboard'>
          <button
            onClick={handleCopy}
            className='button-hover group flex h-[20px] w-[20px] items-center justify-center rounded p-0.5 transition-colors'>
            <CopyIcon color='text-neutral-8 group-active:text-link transition-colors' />
          </button>
        </Tooltip>
      )}
      {succeeded && (
        <Tooltip content='Copied'>
          <div className='flex h-[20px] w-[20px] items-center justify-center p-0.5'>
            <CheckIcon color='text-status-success' />
          </div>
        </Tooltip>
      )}
    </>
  );
});

export { CopyButton };
