import React, { useEffect, useState } from 'react';
import { Portal, Transition } from '@headlessui/react';

import { useMount } from '../hooks';

const Modal = ({ children, open, onClose }) => {
  const mounted = useMount(open);

  const onKeyDown = (e) => {
    if (e.key === 'Escape') {
      onClose();
    }
  };

  useEffect(() => {
    if (!open) {
      return;
    }
    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [open]);

  if (!mounted) {
    return null;
  }

  return (
    <Portal>
      <Layout open={open} onClose={onClose}>
        {children}
      </Layout>
    </Portal>
  );
};

const Layout = ({ open, onClose, children }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(open);
  }, [open]);

  return (
    <div
      role='dialog'
      className='fixed inset-0 z-20 flex items-center justify-center'>
      <Transition show={show}>
        <div
          className='fixed inset-0 backdrop-blur-lg transition-opacity duration-300 data-[closed]:opacity-0'
          onClick={onClose}
        />
      </Transition>
      <Transition show={show}>
        <div className='z-10 m-10 rounded-lg bg-white shadow-card transition-opacity duration-300 data-[closed]:opacity-0'>
          {children}
        </div>
      </Transition>
    </div>
  );
};

export { Modal };
